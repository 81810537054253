import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
import { app } from '@/main';

class CustomCalendarUtils {
    static getSeconds(value: string, workHours = 24): number {
        if (!value) return 0;

        const translatedDuration = this.translateDurationSymbols(value);

        let seconds = 0;

        const array = translatedDuration.split(' ');

        array.forEach((item) => {
            const indicator = item[item.length - 1]?.toLowerCase();
            const validValue = parseFloat(item);

            if (!validValue) return;

            if (indicator === 'w') {
                seconds += validValue * 7 * workHours * 60 * 60;
            } else if (indicator === 'd') {
                seconds += validValue * workHours * 60 * 60;
            } else if (indicator === 'h') {
                seconds += validValue * 60 * 60;
            } else if (indicator === 'm') {
                seconds += validValue * 60;
            }
        });

        return seconds;
    }

    static isWeekOff(weekends: number[], day: Dayjs): boolean {
        const currentWeekDay = dayjs(day).day();
        return weekends.includes(currentWeekDay);
    }

    static isDayOff(holidays: string[], day: Dayjs): boolean {
        if (holidays.length === 0) return false;

        if (!Array.isArray(holidays)) {
            holidays = [holidays];
        }

        return holidays.some((holiday) => dayjs(holiday).isSame(dayjs(day), 'day'));
    }

    static formatDuration({ days, hours, minutes }: { days: number; hours: number; minutes: number }) {
        let result = '';

        const localeSymbols = {
            en: { weeks: 'w', days: 'd', hours: 'h', minutes: 'm' },
            uk: { weeks: 'т', days: 'д', hours: 'г', minutes: 'х' },
            pl: { weeks: 't', days: 'd', hours: 'g', minutes: 'm' }
        };

        const locale = (app?.config?.globalProperties?.$i18n?.locale as 'en' | 'uk' | 'pl') || 'en';

        const symbols = localeSymbols[locale];
        if (days >= 7) {
            const weeks = Math.floor(days / 7);
            days = days % 7;
            result += `${weeks}${symbols.weeks} `;
        }

        if (days) result += `${days}${symbols.days}`;
        if (hours) result += ` ${hours}${symbols.hours}`;
        if (minutes) result += ` ${minutes}${symbols.minutes}`;

        return result.trim();
    }

    static countWorkMinutes(start: Date | string | number, end: Date | string | number): number {
        let count = 0;
        const startTime = dayjs(start).minute();
        const endTime = dayjs(end).minute();
        if (startTime < endTime) {
            count = endTime - startTime;
        } else if (endTime < startTime) {
            count = 0 - endTime + startTime - 60;
        }

        return count;
    }

    static countWorkHours(hoursDayLoad: boolean, planningStartHour: number, planningEndHour: number, start: Date, end: Date): number {
        let count = 0;
        const startTime = dayjs(start).hour();
        const endTime = dayjs(end).hour();

        if (startTime < endTime) {
            count = endTime - startTime;
        } else if (endTime < startTime) {
            count = hoursDayLoad ? planningStartHour - endTime + startTime - planningEndHour : endTime + 24 - startTime;
        }
        return count;
    }

    static countWorkDays(weekends: number[], holidays: string[], start: Date, end: Date) {
        let workdays = 0;
        const startDate = dayjs(start).startOf('day');
        const endDate = dayjs(end).startOf('day');

        for (let current = startDate; current.isSameOrBefore(endDate, 'day'); current = current.add(1, 'day')) {
            if (!this.isWeekOff(weekends, current)) {
                workdays += 1;
            }
        }

        if (holidays?.length) {
            holidays.forEach((holiday) => {
                const holidayDate = dayjs(holiday).startOf('day');
                if (holidayDate.isBetween(startDate, endDate, 'day', '[]')) {
                    workdays -= 1;
                }
            });
        }

        return workdays;
    }

    static isDateTimeValid(value: string, matchWorkTime: boolean, planningStartHour: number, planningEndHour: number) {
        const regex = /^([0-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4} (0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;

        if (!regex.test(value)) {
            return false;
        }

        const [datePart, timePart] = value.split(' ');
        const [day, month, year] = datePart.split('/').map(Number);
        const [hour, minute] = timePart.split(':').map(Number);

        const isLeapYear = (year: number) => (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

        const daysInMonth = (year: number, month: number) => {
            const monthDays = [31, isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
            return monthDays[month - 1];
        };

        const isValidDay = day >= 1 && day <= daysInMonth(year, month);
        const isValidMonth = month >= 1 && month <= 12;
        const isValidYear = year >= 1991 && year <= 2200;
        const isValidHour = hour >= 0 && hour <= 23;
        const isValidMinute = minute >= 0 && minute <= 59;

        let isWithinWorkingHours = true;
        if (matchWorkTime) {
            isWithinWorkingHours = hour >= planningStartHour && hour <= planningEndHour;
            if (hour === planningEndHour) {
                isWithinWorkingHours = minute === 0;
            }
        }

        return isValidDay && isValidMonth && isValidYear && isValidHour && isValidMinute && isWithinWorkingHours;
    }

    static addTimeExcludingWeekends(date: Dayjs, secondsToAdd: number) {
        let currentDate = date;

        while (secondsToAdd > 0) {
            const isWeekend = currentDate.day() === 6 || currentDate.day() === 0;

            if (!isWeekend) {
                const currentHour = currentDate.hour();
                const currentMinutes = currentDate.minute();
                const endOfDayHour = 16;
                const startOfDayHour = 8;

                if (currentHour >= endOfDayHour) {
                    currentDate = currentDate.add(1, 'day').hour(startOfDayHour).minute(0).second(0);
                    continue;
                }

                const remainingWorkSeconds = ((endOfDayHour - currentHour) * 60 * 60) - currentMinutes * 60;

                if (secondsToAdd <= remainingWorkSeconds) {
                    currentDate = currentDate.add(secondsToAdd, 'second');
                    secondsToAdd = 0;
                } else {
                    currentDate = currentDate.add(remainingWorkSeconds, 'second');
                    secondsToAdd -= remainingWorkSeconds;
                    currentDate = currentDate.add(1, 'day').hour(startOfDayHour).minute(0).second(0);
                }
            } else {
                if (currentDate.day() === 6) {
                    currentDate = currentDate.add(2, 'day').hour(8).minute(0).second(0);
                } else if (currentDate.day() === 0) {
                    currentDate = currentDate.add(1, 'day').hour(8).minute(0).second(0);
                }
            }
        }
        return currentDate;
    }

    static getDurationFromSeconds(seconds: number, hoursPerDay: number) {
        const workHoursInSeconds = hoursPerDay * 60 * 60;
        const days = Math.floor(seconds / workHoursInSeconds);
        const hours = Math.floor((seconds % workHoursInSeconds) / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);

        return { days, hours, minutes };
    }

    static isDurationValid(start: number, end: number, duration: number) {
        return start < end && end - start >= duration;
    }

    static addSpaceAfterDurationSymbols(value: string) {
        const lastChar = value.slice(-1);
        const lastCharLowerCase = value.slice(-1).toLowerCase();

        const symbols = ['w', 'т', 't', 'd', 'д', 'h', 'г', 'g'];

        if (symbols.includes(lastCharLowerCase)) {
            if (value.slice(-2, -1) !== ' ') {
                return value.slice(0, -1) + lastChar + ' ';
            }
        }
        return value;
    }

    static formatDurationForSeconds(value: string) {
        return value.trim().replace(/\s+/g, ' ').replace(/(\d+)([wdhm])/g, '$1$2 ').trim();
    }

    static translateDurationSymbols(value: string) {
        const translations: { [key: string]: string } = {
            'т': 'w',  // Ukrainian 'н' to 'w' for weeks, if needed
            'д': 'd', // Ukrainian 'т' to 'd' for days
            'г': 'h', // Ukrainian 'г' to 'h' for hours
            'х': 'm', // Ukrainian 'х' to 'h' for minutes

            't': 'w',  // Polish 't' to 'w' for weeks, if needed
            'g': 'h', // Polish 'g' to 'h' for hours
        };

        return value.toLowerCase().replace(/[тгхдtg]/gi, (char: string) =>
            translations[char as keyof typeof translations] || char
        );
    }

    static hoursToSeconds(hours: number): number {
        return hours * 60 * 60;
    }

    static subtractTimeExcludingWeekends(date: Dayjs, secondsToSubtract: number, weekends: number[], holidays: string[]) {
        let currentDate = dayjs(date, 'DD/MM/YYYY HH:mm');
        while (secondsToSubtract > 0) {
            const isWeekend = this.isWeekOff(weekends, currentDate) || this.isDayOff(holidays, currentDate);
            const startOfDayHour = 8;
            const endOfDayHour = 16;
            if (!isWeekend) {
                const currentHour = currentDate.hour();


                // Check if current time is before work hours
                if (currentHour < startOfDayHour) {
                    // Move to the previous workday at the end of working hours
                    currentDate = currentDate.subtract(1, 'day').hour(endOfDayHour).minute(0).second(0);
                    continue;
                }

                // Calculate worked seconds passed in the current day
                const workedSeconds = (currentHour - startOfDayHour) * 60 * 60;

                // If remaining time fits within the current workday
                if (secondsToSubtract <= workedSeconds) {
                    currentDate = currentDate.subtract(secondsToSubtract, 'second');
                    secondsToSubtract = 0; // All time subtracted
                } else {
                    // Otherwise, subtract the available time and move to the previous workday
                    currentDate = currentDate.subtract(workedSeconds, 'second');
                    secondsToSubtract -= workedSeconds;
                    // Move to the previous workday
                    currentDate = currentDate.subtract(1, 'day').hour(endOfDayHour).minute(0).second(0);
                }
            } else {
                // If it's a weekend, move to the previous Friday at 16:00
                if (currentDate.day() === 6) {
                    // Saturday
                    currentDate = currentDate.subtract(1, 'day').hour(endOfDayHour).minute(0).second(0);
                } else if (currentDate.day() === 0) {
                    // Sunday
                    currentDate = currentDate.subtract(2, 'day').hour(endOfDayHour).minute(0).second(0);
                }
            }
        }
        return currentDate;
    }

    static createElapsedDateArray = (minCalendarDate: string, maxCalendarDate: string): Element[] => {
        const start = minCalendarDate;
        const end = maxCalendarDate;
        if (!start || !end) return [];

        const dayToTransform: Element[] = [];
        let currentDate = dayjs(start);
        while (currentDate.isBefore(end, 'day') || currentDate.isSame(end, 'day')) {
            const formattedDate = currentDate.format('YYYY-MM-DD');
            const element = document.querySelector(`.id-${formattedDate}.in-month`) as HTMLElement;
            if (element) dayToTransform.push(element);
            currentDate = currentDate.add(1, 'day');
        }

        return dayToTransform;
    };
}

export { CustomCalendarUtils };
