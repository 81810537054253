import { vTooltip } from 'floating-vue';

export default {
    ...vTooltip,
    beforeMount(el, binding) {
        const isOverflowing = el.scrollWidth > el.clientWidth || el.scrollHeight === 0 || el.scrollHeight > el.clientHeight;
        const tooltipValue = binding.value?.length;
        const contentValue = el.textContent.trim()?.length;
        const isTooltipDifferent = tooltipValue !== contentValue;
        //console.log('******', contentValue, isOverflowing, isTooltipDifferent, el.scrollWidth, el.clientWidth, el.scrollHeight);
        if (!isOverflowing && !isTooltipDifferent) {
            vTooltip.beforeUnmount(el);
        } else {
            vTooltip.beforeMount(el, binding);
        }
    },
    updated(el, binding) {
        const isOverflowing = el.scrollWidth > el.clientWidth || el.scrollHeight === 0 || el.scrollHeight > el.clientHeight;
        const tooltipValue = binding.value?.length;
        const contentValue = el.textContent.trim()?.length;
        const isTooltipDifferent = tooltipValue !== contentValue;
        // console.log('++++', contentValue, isOverflowing, isTooltipDifferent, el.scrollWidth, el.clientWidth, el.scrollHeight, el.scrollWidth > el.clientWidth, el.scrollHeight === 0);
        if (!isOverflowing && !isTooltipDifferent) {
            vTooltip.beforeUnmount(el);
        } else {
            vTooltip.updated(el, binding);
        }
    }
};
