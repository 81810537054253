<template>
    <div class="flex flex-col" id="platform-app" ref="platformApp" @mouseup="onPlatformAppMouseUp">
        <div class="platform-header-container relative" v-if="isAuthorize">
            <PlatformHeader />
        </div>
        <div ref="fullPageContentRect" class="main-content flex flex-col grow bg-white overflow-y-auto" :style="mainContentStyle">
            <div class="flex flex-col grow relative platform-scroller content-wraper max-h-full overflow-hidden" ref="appScroller">
                <div class="app__page flex max-h-full max-w-full">
                    <div
                        v-if="!isMobile"
                        class="sub_navigation h-full max-h-full flex flex-col relative"
                        :class="[{ 'z-10': submenuOpenedOnHover }]"
                        :style="submenuContainerStyle"
                        id="left-panel-target">
                        <!-- v-if="showContentLeftPanel" -->
                        <div id="content-left-panel" class="h-full" :class="submenuOpenedOnHover ? 'absolute' : 'relative'" :style="submenuContentStyle"></div>
                        <!-- <ContentLeftPanel
              :open="submenuOpen"
              :panelWidth="submenuWidth"
              @toggle-left-panel="handleToogleLeftPanel"
              @change-left-panel-width="handleLeftPanelWidth"
            ></ContentLeftPanel> -->
                    </div>
                    <div ref="mainPageContent" class="flex flex-col grow h-full max-h-full overflow-x-auto">
                        <div class="bg-[#FFFFFF]" v-if="isAuthorize">
                            <platform-topbar></platform-topbar>
                        </div>
                        <div class="page__content overflow-y-auto">
                            <!-- :style="pageContentStyle" -->
                            <router-view></router-view>
                            <base-loader position="global" v-if="showLoader" />
                        </div>
                    </div>
                    <div ref="mainPageContentLeftPanel" class="platform-peek-renderer" :class="{ hidden: sliderWidth === 0 }">
                        <div class="flex flex-col peek-wrapper h-full">
                            <div class="posit unselectable" :style="rightSliderResizerStyle" @mousedown.stop="onPeekResizerMouseDown" @mouseup="onPeekResizerMouseUp">
                                <div class="resizer" ref="peekResizer"></div>
                            </div>
                            <div id="peek-container" class="flex flex-col" :class="[{ 'slider-shadow': sliderWidth }, { 'max-w-[100vw]': isMobile }]" style="height: 100%"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="footer"></div>
        </div>
    </div>
</template>

<script>
import PlatformHeader from '@/components/platformHeader/PlatformHeader.vue';
import PlatformTopbar from './topbar/PlatformTopbar.vue';
import BaseLoader from '@/components/ui/BaseLoader.vue';

import { mapActions, mapState } from 'pinia';
import { useUserStore } from '@/stores/userStore';
import { getUserLocale, setDateLocale } from '@/i18n';

import { computed } from 'vue';
import { useMultiModalFormStore } from '@/stores/multiModalForm';

export default {
    name: 'MainTemplate',
    components: {
        PlatformHeader,
        PlatformTopbar,
        BaseLoader
    },
    data() {
        return {
            documentWidth: null,

            submenuWidth: 190,
            submenuFullWidth: 230,
            submenuClosedWidth: 20,
            submenuOpenedOnHover: false,
            submenuContentWidth: 190,
            submenuTimerOpen: null,

            sliderWidth: 0,
            sliderFullWidth: 580,
            showContentLeftPanel: false
        };
    },
    computed: {
        ...mapState(useUserStore, ['isAuthorize', 'currentLocale', 'user', 'isMobile']),
        isMainTemplate() {
            return this.$route.meta.template !== 'LandingTemplate';
        },
        submenuWidthStorageName() {
            return this.$route?.name === 'tasks' ? 'submenuWidthTasks' : 'submenuWidth';
        },
        submenuOpen() {
            return this.submenuContentWidth > this.submenuClosedWidth;
        },
        submenuOpenByHover() {
            return this.submenuOpenedOnHover;
        },
        mainContentStyle() {
            // return this.isMainTemplate
            //   ? `width:${this.documentWidth - this.sidebarWidth - 8}px`
            //   : `width:100%`;
            return `width:100%`;
        },
        submenuContainerStyle() {
            return `width:${this.submenuWidth}px;min-width:${this.submenuWidth}px`;
        },
        submenuContentStyle() {
            return `width:${this.submenuContentWidth}px;min-width:${this.submenuContentWidth}px`;
        },
        rightSliderWidth() {
            return `${this.sliderWidth}px`;
        },
        rightSliderResizerStyle() {
            // if (this.sliderWidth) return "";
            // return "display:none";
            return '';
        },
        showLoader() {
            return this.$loaderSpiner.show;
        },
        isHRPages() {
            return [
                'f30e69a2-da73-46d9-9f49-38b02454a5ad',
                'df5eef28-4aa1-413a-9ac8-7a36a7e1eb5a',
                'e4f9aa20-7318-46cf-b595-082ba7cf1d81',
                '7f69865c-962b-48c5-96f3-00c7595ce69c',
                '5d4ef3c5-3f47-4121-b6ae-e3f869825e2d',
                '1a7af67a-bfbf-4883-8ebd-39556a6522a3'
            ].includes(this.$route?.params?.entity_id);
        }
    },
    watch: {
        $route(to) {
            if (to.meta?.hasContentLeftPanel || this.isHRPages) {
                if (localStorage.getItem(this.submenuWidthStorageName)) {
                    this.submenuWidth = +localStorage.getItem(this.submenuWidthStorageName);
                } else this.submenuWidth = 190;
                this.showContentLeftPanel = true;
            } else {
                this.submenuWidth = 0;
                this.showContentLeftPanel = false;
            }
            this.submenuContentWidth = this.submenuWidth;
            if (this.submenuOpenedOnHover) {
                this.submenuOpenedOnHover = false;
            }
        }
    },
    methods: {
        ...mapActions(useMultiModalFormStore, ['setMainPageContentRect', 'setMainPageLeftContentRect', 'setFullPageContentRect']),
        watchForWindowsResize() {
            this.documentWidth = window.innerWidth;
            // this.setMainPageContentRect(this.$refs.mainPageContent.getBoundingClientRect());
        },
        animate({ duration, extion, timing }) {
            let start = performance.now();
            requestAnimationFrame(function animate(time) {
                let timeFraction = (time - start) / duration;
                if (timeFraction > 1) timeFraction = 1;
                let progress = timing(timeFraction);
                extion(progress);
                if (timeFraction < 1) {
                    requestAnimationFrame(animate);
                }
            });
        },
        handlerOpenLeftPanel(width = null) {
            if (width) {
                this.submenuFullWidth = width;
                this.submenuWidth = width;
                this.submenuContentWidth = this.submenuWidth;
            }
        },
        handleToogleLeftPanel() {
            clearTimeout(this.submenuTimerOpen);
            const andValue = this.submenuFullWidth;
            if (this.submenuWidth > this.submenuClosedWidth) {
                this.animate({
                    duration: 200,
                    timing: function (timeFraction) {
                        return timeFraction;
                    },
                    extion: progress => {
                        if (progress > 0.1 && this.submenuWidth > this.submenuClosedWidth) {
                            this.submenuWidth = andValue - andValue * progress;
                            if (this.submenuWidth < this.submenuClosedWidth) this.submenuWidth = this.submenuClosedWidth;
                            this.submenuContentWidth = this.submenuWidth;
                            localStorage.setItem(this.submenuWidthStorageName, this.submenuWidth);
                        }
                    }
                });
            } else {
                this.animate({
                    duration: 200,
                    timing: function (timeFraction) {
                        return timeFraction;
                    },
                    extion: progress => {
                        if (progress > 0.1) {
                            this.submenuWidth = this.submenuClosedWidth + (andValue - this.submenuClosedWidth) * progress;

                            if (!this.submenuOpenedOnHover) this.submenuContentWidth = this.submenuWidth;
                            localStorage.setItem(this.submenuWidthStorageName, this.submenuWidth);
                        }
                        if (progress === 1) {
                            this.submenuOpenedOnHover = false;
                        }
                    }
                });
            }
        },

        handleToogleLeftPanelContent() {
            const andValue = this.submenuFullWidth;
            if (this.submenuOpenedOnHover) {
                this.animate({
                    duration: 200,
                    timing: function (timeFraction) {
                        return timeFraction;
                    },
                    extion: progress => {
                        if (progress > 0.1 && this.submenuContentWidth > this.submenuClosedWidth) {
                            this.submenuContentWidth = andValue - andValue * progress;
                            if (this.submenuContentWidth < this.submenuClosedWidth) this.submenuContentWidth = this.submenuClosedWidth;
                        }
                        if (progress === 1) this.submenuOpenedOnHover = false;
                    }
                });
            } else {
                this.submenuOpenedOnHover = true;
                this.animate({
                    duration: 200,
                    timing: function (timeFraction) {
                        return timeFraction;
                    },
                    extion: progress => {
                        if (progress > 0.1) {
                            this.submenuContentWidth = this.submenuClosedWidth + (andValue - this.submenuClosedWidth) * progress;
                        }
                    }
                });
            }
        },

        closeLeftPanel() {
            const andValue = this.submenuFullWidth;
            if (this.submenuWidth > this.submenuClosedWidth) {
                this.animate({
                    duration: 250,
                    timing: function (timeFraction) {
                        return timeFraction;
                    },
                    extion: progress => {
                        if (progress > 0.1 && this.submenuWidth > this.submenuClosedWidth) {
                            this.submenuWidth = andValue - andValue * progress;
                            if (this.submenuWidth < this.submenuClosedWidth) this.submenuWidth = this.submenuClosedWidth;
                            localStorage.setItem(this.submenuWidthStorageName, this.submenuWidth);
                        }
                    }
                });
            }
        },
        handleLeftPanelWidth(width) {
            if (width > 120 && width <= 360) {
                this.submenuFullWidth = width;
                localStorage.setItem('submenuFullWidth', this.submenuFullWidth);
                if (this.submenuWidth > 0) {
                    this.submenuWidth = width;
                    localStorage.setItem(this.submenuWidthStorageName, this.submenuWidth);
                    this.submenuContentWidth = this.submenuWidth;
                }
            }
        },
        ///**** */
        movePeek(e) {
            // const el = this.$refs.peekResizer;
            let eventPoz = this.documentWidth - e.x;
            if (eventPoz > 580) {
                // el.style.left = this.sliderWidth - eventPoz + "px";
                this.sliderWidth = this.documentWidth - e.x;
                this.sliderFullWidth = this.documentWidth - e.x;
                localStorage.setItem('sliderFullWidth', this.sliderFullWidth);
            }
        },
        onPeekResizerMouseDown() {
            const el = this.$refs.platformApp;
            const resizer = this.$refs.peekResizer;
            resizer.style.background = '#34cdf3';
            el.addEventListener('mousemove', this.movePeek, false);
        },
        onPeekResizerMouseUp() {
            const resizer = this.$refs.peekResizer;
            resizer.style.background = 'transparent';
            const el = this.$refs.platformApp;
            el.removeEventListener('mousemove', this.movePeek, false);
        },
        openSlider() {
            if (this.isMobile) {
                this.sliderFullWidth = this.documentWidth;
            }
            console.log('openSlider', this.sliderFullWidth);
            const andValue = this.sliderFullWidth;
            this.animate({
                duration: 250,
                timing: function (timeFraction) {
                    return timeFraction;
                },
                extion: progress => {
                    if (progress > 0.1) {
                        this.sliderWidth = (andValue + andValue * progress) / 2;
                    }
                }
            });
            // if (
            //   this.sidebarWidth > this.widthClosedSidebar &&
            //   this.documentWidth - (this.sidebarWidth + andValue) < 400
            // ) {
            //   this.closeLeftPanel();
            // }
        },
        closeSlider() {
            console.log('closeSlider');
            const andValue = this.sliderFullWidth;
            this.animate({
                duration: 250,
                timing: function (timeFraction) {
                    return timeFraction;
                },
                extion: progress => {
                    if (progress > 0.1) {
                        this.sliderWidth = andValue - andValue * progress;
                    }
                }
            });
        },
        onPlatformAppMouseUp() {
            this.onPeekResizerMouseUp();
        },

        changeLang(value) {
            let shortLang = value.split('_')[0];
            this.$i18n.locale = shortLang;
            setDateLocale(shortLang);
            document.querySelector('html').setAttribute('lang', shortLang);
            localStorage.setItem('lang', value);
        },

        correctLang() {
            const userLocale = getUserLocale();
            if (this.isAuthorize && userLocale !== this.currentLocale.split('_')[0]) {
                this.changeLang(this.currentLocale);
            }
        },
        onSubmenuMouseEnter() {
            if (this.submenuWidth === this.submenuClosedWidth) {
                this.submenuTimerOpen = setTimeout(() => {
                    if (this.submenuOpenedOnHover === false) this.handleToogleLeftPanelContent();
                }, 250);
            }
        },
        onSubmenuMouseLeave() {
            clearTimeout(this.submenuTimerOpen);
            if (this.submenuWidth === this.submenuClosedWidth && this.submenuOpenedOnHover === true) this.handleToogleLeftPanelContent();
        },
        setSubmenuParams(params) {
            if (!params) return;
            this.submenuWidth = params.width;
            this.submenuContentWidth = params.width;
            this.showContentLeftPanel = params.width > 0;
        }
    },
    provide() {
        return {
            openSlider: this.openSlider,
            closeSlider: this.closeSlider,
            toggleLeftPanel: this.handleToogleLeftPanel,
            openleLeftPanel: this.handlerOpenLeftPanel,
            changeLeftPanelWidth: this.handleLeftPanelWidth,
            onSubmenuMouseEnter: this.onSubmenuMouseEnter,
            onSubmenuMouseLeave: this.onSubmenuMouseLeave,
            setSubmenuParams: this.setSubmenuParams,
            submenuOpen: computed(() => this.submenuOpen),
            submenuOpenByHover: computed(() => this.submenuOpenByHover)
        };
    },
    created() {},
    mounted() {
        this.documentWidth = window.innerWidth;
        window.addEventListener('resize', this.watchForWindowsResize);

        if (!this.isMobile) {
            if (localStorage.getItem(this.submenuWidthStorageName)) {
                this.submenuWidth = +localStorage.getItem(this.submenuWidthStorageName);
            } else localStorage.setItem(this.submenuWidthStorageName, this.submenuWidth);
            if (localStorage.getItem('submenuFullWidth')) {
                this.submenuFullWidth = +localStorage.getItem('submenuFullWidth');
            } else localStorage.setItem('submenuFullWidth', this.submenuFullWidth);
            if (localStorage.getItem('sliderFullWidth')) {
                this.sliderFullWidth = +localStorage.getItem('sliderFullWidth');
            } else localStorage.setItem('sliderFullWidth', this.sliderFullWidth);
        } else {
            this.sliderFullWidth = this.documentWidth;
            localStorage.removeItem('sliderFullWidth');
            localStorage.removeItem('submenuFullWidth');
        }

        this.correctLang();

        if (this.$refs?.mainPageContent) {
            this.$nextTick(() => {
                this.setMainPageContentRect(this.$refs.mainPageContent);
            });
        }
        if (this.$refs?.mainPageContentLeftPanel) {
            this.$nextTick(() => {
                this.setMainPageLeftContentRect(this.$refs.mainPageContentLeftPanel);
            });
        }
        if (this.$refs?.fullPageContentRect) {
            this.$nextTick(() => {
                this.setFullPageContentRect(this.$refs.fullPageContentRect);
            });
        }
    },
    unmounted() {
        console.log('MainTemplate Template unmounted');
        window.removeEventListener('resize', this.watchForWindowsResize);
    }
};
</script>
<style lang="scss">
#platform-app {
    height: 100dvh;
    overflow: hidden;
    position: relative;
}
.platform-sidebar-container {
    background: linear-gradient(270deg, #efefef 0%, #f8f8f8 100%);
}
.platform-header-container {
    background: #252525;
}

.platform-scroller {
    z-index: 1;
    margin-right: 0px;
    margin-bottom: 0px;
}
.app__page {
    position: relative;
    display: flex;
    flex-grow: 1;
    height: 100%;
    border-radius: var(--sp-border-radius, 7px);

    .page__content {
        position: relative;
        display: flex;
        flex-grow: 1;
        /* height: 100%; */
    }
}
.platform-peek-renderer {
    position: relative;
    width: v-bind(rightSliderWidth);
    min-width: v-bind(rightSliderWidth);
    /* box-shadow: -6px 0px 16px 0px rgba(0, 0, 0, 0.5); */
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    .peek-border {
        border-top-left-radius: 10px;
    }
    .right-slider__content .task-wrap {
        border-bottom-left-radius: 10px;
    }
}
.posit {
    position: absolute;
    left: 0px;
    width: 0px;
    flex-grow: 0;
    z-index: 109;
    top: 0px;
    bottom: 0px;
    pointer-events: auto;

    .resizer {
        cursor: col-resize;
        height: 100%;
        width: 2px;
        margin-left: -1px;
        // background:#34cdf3
        background: transparent;

        &:hover {
            background: #759ea8e0;
        }
    }
}

.slider-shadow {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
    box-shadow: -4px -4px 15px 0px #686c6f26;
}

.platform-header-modal-background {
    // background: linear-gradient(90.19deg, #f0f3f8 0%, #f4f6fa 100%);
    background: #f9f9f9;

    border-bottom: 1px solid #eaebee;
}
</style>
