import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dc1ad4f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "datepicker__wrapper w-full relative" }
const _hoisted_2 = {
  key: 0,
  class: "text-[#FF7C73]"
}
const _hoisted_3 = ["placeholder", "value", "disabled"]
const _hoisted_4 = { class: "max-h-6 shrink-0" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "datepicker__modal-container flex flex-col" }
const _hoisted_8 = {
  key: 2,
  class: "datepicker__confirm-buttons p-4 flex gap-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_SmartModal = _resolveComponent("SmartModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: _normalizeClass(["datepicker__label", [{ 'pl-2': _ctx.variantText }]]),
          style: _normalizeStyle(_ctx.labelStyle)
        }, [
          _createTextVNode(_toDisplayString(_ctx.label), 1),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
            : _createCommentVNode("", true)
        ], 6))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["datepicker__container-wrapper", { 'cursor-not-allowed': _ctx.readonly }])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["datepicker__container cursor-pointer bg-[#FFFFFF]", {
                    'border hover:border-[#979BB2] pr-4': _ctx.variantBase,
                    'border border-transparent hover:bg-[#F9F9F9] pr-2': _ctx.variantText,
                    'border-y-2 hover:border-b-[#979BB2] !border-t-transparent pr-4': _ctx.variantUnderline,
                    'pointer-events-none': _ctx.readonly,
                    'border-[#F9F9F9]': _ctx.valid && _ctx.variantUnderline,
                    'border-[#C0C2CD]': _ctx.valid && !_ctx.variantUnderline,
                    '!border-[#FF7C73]': !_ctx.valid,
                    'cursor-pointer': !_ctx.readonly,
                    'cursor-default': _ctx.readonly,
                    'active-border !bg-[#FFFFFF]': _ctx.showCalendarModal
                }]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCalendarModal && _ctx.toggleCalendarModal(...args))),
        style: _normalizeStyle(_ctx.containerStyle),
        ref: "datepickerContainer"
      }, [
        _createElementVNode("input", {
          type: "text",
          class: _normalizeClass(["active-date__input pointer-events-none", [{ '!pl-2': _ctx.variantText }]]),
          placeholder: _ctx.placeholder || 'DD.MM.YYYY HH:mm',
          value: _ctx.inputValue,
          disabled: _ctx.readonly
        }, null, 10, _hoisted_3),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            type: "button",
            "data-action": "openCalendar",
            class: "w-6 h-6 flex items-center justify-center",
            disabled: _ctx.readonly
          }, [
            _createVNode(_component_BaseIcon, {
              class: "button__icon fill-[#C0C2CD] h-5 w-5 hover:fill-[#519DF5]",
              name: "calendarNew"
            })
          ], 8, _hoisted_5)
        ])
      ], 6),
      (_ctx.showResetButton && Boolean(_ctx.componentValue) && !_ctx.readonly)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "reset__button cursor-pointer w-6 h-6 flex items-center",
            style: _normalizeStyle({ bottom: _ctx.resetButtonPosition }),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleReset())),
            disabled: _ctx.readonly
          }, [
            _createVNode(_component_BaseIcon, {
              class: "button__icon h-6 w-6",
              name: "close"
            })
          ], 12, _hoisted_6))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.showCalendarModal)
      ? (_openBlock(), _createBlock(_component_SmartModal, {
          key: 1,
          target: _ctx.datepickerContainer,
          onClose: _ctx.closeCalendarModal
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              (!_ctx.range)
                ? (_openBlock(), _createBlock(_component_DatePicker, _mergeProps({
                    key: 0,
                    modelValue: _ctx.datePickerValue,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.datePickerValue) = $event)),
                    modelModifiers: { number: true }
                  }, _ctx.calendarProps, {
                    rules: _ctx.rules,
                    onDayclick: _ctx.handleDayClick,
                    attributes: _ctx.attrs
                  }), null, 16, ["modelValue", "rules", "onDayclick", "attributes"]))
                : _createCommentVNode("", true),
              (_ctx.range)
                ? (_openBlock(), _createBlock(_component_DatePicker, _mergeProps({
                    key: 1,
                    modelValue: _ctx.datePickerValue,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.datePickerValue) = $event)),
                    modelModifiers: { range: true, number: true }
                  }, _ctx.calendarProps, {
                    rules: _ctx.rules,
                    attributes: _ctx.attrs
                  }), null, 16, ["modelValue", "rules", "attributes"]))
                : _createCommentVNode("", true),
              (_ctx.showActionsBtn)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_component_BaseButton, {
                      color: "secondary",
                      onClick: _ctx.closeCalendarModal,
                      label: _ctx.$t('buttons.cancel'),
                      class: "w-[100px]"
                    }, null, 8, ["onClick", "label"]),
                    _createVNode(_component_BaseButton, {
                      onClick: _ctx.onSave,
                      label: _ctx.$t('buttons.save'),
                      class: "w-[100px]"
                    }, null, 8, ["onClick", "label"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["target", "onClose"]))
      : _createCommentVNode("", true)
  ]))
}