// Custom forms: UserInformation, ProjectCard
// Not custom forms: CRMActivities, CRMDeal, CRMLead, CRMContact, CRMAccount, TaskCard, WorkflowCard

import { uuid } from 'vue-uuid';
import { useUserStore } from '@/stores/userStore';
import { useTasksStore } from '@/stores/tasksStore';
import { useEntitysStore } from '@/stores/entityStore';

export async function getCardSettings(cardName, formType, activityType, instance, entityId) {
    switch (cardName) {
        case 'CRMActivities':
            return getDefaultCrmActivitySettings(activityType, formType, instance, entityId);
        case 'CRMDeal':
            return getDealCardSettings(formType, instance, entityId);
        case 'CRMLead':
            return getLeadCardSettings(formType, instance, entityId);
        case 'CRMContact':
            return getContactCardSettings(formType, instance, entityId);
        case 'CRMAccount':
            return getAccountCardSettings(formType, instance, entityId);
        case 'WorkflowCard':
            return getWorkflowCardSettings(instance, entityId);
        case 'TaskCard':
            return getTaskCardSettings(formType, instance, entityId);
        case 'ProjectCard':
            return getProjectCardSettings(formType, instance, entityId);
        case 'UserInformation':
            return getUserCardSettings(formType, instance, entityId);
        default:
            return await getDefaultSettings(formType, instance, entityId);
    }
}

export async function getDefaultSettings(formType, instance, entityId) {
    if (formType === 'createForm') {
        return getDefaultCreateCardSettings(entityId);
    } else {
        return await getEntityCardSettings(formType, instance.id, entityId);
    }
}

export async function getEntityCardSettings(formType = 'elementForm', instanceId, entityId) {
    const entitysStore = useEntitysStore();
    const elementForm = await entitysStore.getEntityFormByParams(entityId, 'form_type', formType);

    let modalWidth;

    if (elementForm && elementForm?.form_data?.formSettings?.formInitWidth) {
        modalWidth = elementForm.form_data.formSettings.formInitWidth;
    }

    return {
        id: instanceId || '-1',
        name: 'EntityCard', ///EntityCard , DefaultEntityCard,
        entityId,
        system: elementForm?.form_data?.formSettings?.defaultView === 'systemModal',
        trayTitle: elementForm?.form_data?.formSettings?.formTitle ?? elementForm?.form_data?.formSettings?.name,
        cardTitle: elementForm?.form_data?.formSettings?.formTitle ?? elementForm?.form_data?.formSettings?.name,
        stick: {
            width: modalWidth,
            resizable: elementForm?.form_data?.formSettings?.formResizable ?? true,
            storeUserModalSize: elementForm?.form_data?.formSettings?.storeUserModalSize ?? false,
            minw: elementForm?.form_data?.formSettings?.formInitMinWidth ? parseInt(elementForm.form_data.formSettings.formInitMinWidth) : 300,
            minh: elementForm?.form_data?.formSettings?.formInitMinHeight ? parseInt(elementForm.form_data.formSettings.formInitMinHeight) : 300,
            isFullSize: !!elementForm?.form_data?.formSettings?.formInitFullScreen,
            isNotModalFullSize: true,
            horizontalPosition: elementForm?.form_data?.formSettings?.horizontalPosition,
            formId: elementForm?.id
        },

        props: {
            id: instanceId || '-1',
            entityId,
            formType
        },
        events: {},
        onClose: () => ({})
    };
}

export function getDefaultCreateCardSettings(entityId) {
    return {
        id: uuid.v4(),
        name: 'DefaultCreateEntityCard',
        system: true,
        modalProps: {
            horizontalAlign: 'right',
            verticalAlign: 'center',
            class: '',
            fullHeight: true
        },
        props: {
            entityId
        },
        events: {}
    };
}

export function getUserCardSettings(formType, instance, entityId) {
    return formType === 'createForm'
        ? getDefaultCreateCardSettings(entityId)
        : {
              id: instance.id,
              name: 'UserInformation',
              useInFavorite: false,
              useCopyLink: false,
              props: {
                  userId: instance.id
              },
              events: {},
              onClose: () => ({})
          };
}

export function getProjectCardSettings(formType, instance, entityId) {
    const commonProps = {
        highlightNewProject: (id) => {
            const project = document.getElementById(id);
            if (project) {
                const existingClasses = project.className;
                const additionalClasses = 'bg-[#55C47B1a] border-solid border-y border-[#f9f9f9] rounded-md';
                project.className = `${existingClasses} ${additionalClasses}`;
            }
        },
        scrollUp: () => {
            const container = document.querySelector('.navigation-block__tree');
            if (!container) return;
            container.scrollTop = 0;
        }
    }
    return formType === 'createForm'
        ? {
            id: uuid.v4(),
            entityId,
            name: 'NewProjectCard',
            useInFavorite: false,
            useCopyLink: false,
            props: { ...commonProps },
            events: {},
            onClose: () => ({})
        }
        : {
              id: instance.id,
              entityId,
              name: 'ProjectCard',
              props: {
                  id: instance.id,
                  ...commonProps
              },
              events: {},
              onClose: () => ({})
          };
}

export function getTaskCardSettings(formType, instance, entityId) {
    return formType === 'createForm'
        ? {
              icon: 'task-add-01',
              name: 'CreateShortTask',
              props: {
                  cardScope: {
                      cardType: 'create'
                  }
              },
              stick: {
                  minw: 392,
                  width: 392,
                  resizable: false,
                  horizontalPosition: 'right',
                  isNotModalFullSize: true
              },
              system: false,
              cardTitle: 'Create a task',
              iconColor: '#EE87B2',
              trayTitle: 'Create a task',
              modalProps: {
                  class: 'p-4',
                  fullHeight: true,
                  verticalAlign: 'center',
                  horizontalAlign: 'right'
              },
              useCopyLink: false,
              useInFavorite: false
          }
        : {
              id: instance.id,
              entityId,
              name: 'TaskCard',
              props: {
                  id: instance.id
              },
              events: {},
              onClose: () => ({})
          };
}

export function getWorkflowCardSettings(instance, entityId) {
    return {
        id: instance.id,
        entityId,
        name: 'WorkflowCard',
        props: {
            id: instance.id
        },
        events: {},
        onClose: () => ({})
    };
}

export function getAccountCardSettings(formType, instance, entityId) {
    return formType === 'createForm'
        ? getDefaultCreateCardSettings(entityId)
        : {
              id: instance.id,
              entityId: 'CRMAccount',
              name: 'CRMAccount',
              props: {
                  id: instance.id
              },
              events: {},
              onClose: () => ({})
          };
}

export function getContactCardSettings(formType, instance, entityId) {
    return formType === 'createForm'
        ? getDefaultCreateCardSettings(entityId)
        : {
              id: instance.id,
              entityId: 'CRMContact',
              name: 'CRMContact',
              props: {
                  id: instance.id
              },
              events: {},
              onClose: () => ({})
          };
}

export function getDealCardSettings(formType, instance, entityId) {
    return formType === 'createForm'
        ? getDefaultCreateCardSettings(entityId)
        : {
              id: instance.id,
              entityId: 'CRMDeal',
              name: 'CRMDeal',
              props: {
                  id: instance.id
              },
              events: {},
              onClose: () => ({})
          };
}

export function getLeadCardSettings(formType, instance, entityId) {
    return formType === 'createForm'
        ? getDefaultCreateCardSettings(entityId)
        : {
              id: instance.id,
              name: 'CRMLead',
              entityId: 'CRMLead',
              props: {
                  id: instance.id
              },
              events: {},
              onClose: () => ({})
          };
}

export function getDefaultCrmActivitySettings(activityType = 'Meeting', formType, instance, entityId) {
    const userStore = useUserStore();
    const tasksStore = useTasksStore();
    const isMobile = userStore.isMobile;
    const taskEntityId = tasksStore.getTaskEntityId;
    const isCreationForm = formType === 'createForm';

    const modalProps = isMobile
        ? {
              class: '',
              borderRTL: false,
              borderRTR: false,
              borderRBL: false,
              borderRBR: false
          }
        : {
              horizontalAlign: 'right',
              verticalAlign: 'center',
              class: 'p-4',
              fullHeight: true
          };

    const meetingSettings = {
        id: isCreationForm ? uuid.v4() : instance.id,
        name: 'CRMActivities',
        entityId: 'CRMActivities',
        cardName: 'MeetingCard',
        system: false,
        useCopyLink: false,
        useInFavorite: false,
        modalProps,
        props: {
            activityType: 'Meeting'
        },
        stick: isMobile
            ? { isFullSize: true }
            : {
                  minw: 980,
                  resizable: false,
                  width: 980,
                  left: window.innerWidth - 980,
                  top: 56,
                  isNotModalFullSize: true
              },
        events: {},
        onClose: () => ({})
    };

    const taskSettings = isCreationForm
        ? {
              id: uuid.v4(),
              name: 'CRMActivities',
              entityId: 'CRMActivities',
              cardName: 'CreateShortTask',
              system: false,
              useCopyLink: false,
              useInFavorite: false,
              modalProps,
              props: {
                  activityType: 'Task'
              },
              stick: isMobile
                  ? { isFullSize: true }
                  : {
                        minw: 620,
                        resizable: false,
                        width: 620,
                        left: window.innerWidth - 620,
                        top: 56,
                        isNotModalFullSize: true
                    },
              events: {},
              onClose: () => ({})
          }
        : {
              id: instance.sub_instance_id,
              name: 'TaskCard',
              entityId: taskEntityId,
              props: {
                  id: instance.sub_instance_id
              },
              onClose: () => ({})
          };

    const callSettings = {
        id: isCreationForm ? uuid.v4() : instance.id,
        name: 'CRMActivities',
        entityId: 'CRMActivities',
        cardName: 'CallCard',
        system: false,
        useCopyLink: false,
        useInFavorite: false,
        modalProps,
        props: {
            activityType: 'Call'
        },
        stick: isMobile
            ? { isFullSize: true }
            : {
                  minw: 980,
                  resizable: false,
                  width: 980,
                  left: window.innerWidth - 980,
                  top: 56,
                  isNotModalFullSize: true
              },
        events: {},
        onClose: () => ({})
    };

    const emailSettings = {
        id: isCreationForm ? uuid.v4() : instance.id,
        name: 'CRMActivities',
        entityId: 'CRMActivities',
        cardName: 'EmailCard',
        system: false,
        useCopyLink: false,
        useInFavorite: false,
        modalProps,
        props: {
            activityType: 'E-mail'
        },
        stick: isMobile
            ? { isFullSize: true }
            : {
                  minw: 620,
                  resizable: false,
                  width: 620,
                  left: window.innerWidth - 620,
                  top: 56,
                  isNotModalFullSize: true
              },
        events: {},
        onClose: () => ({})
    };

    if (isCreationForm) {
        meetingSettings.props.cardScope = { cardType: 'create' };
        taskSettings.props.cardScope = { cardType: 'create' };
        callSettings.props.cardScope = { cardType: 'create' };
        emailSettings.props.cardScope = { cardType: 'create' };
    } else {
        meetingSettings.id = instance.id;
        taskSettings.id = instance.id;
        callSettings.id = instance.id;
        emailSettings.id = instance.id;

        meetingSettings.props.crmEntityId = entityId;
        taskSettings.props.crmEntityId = entityId;
        callSettings.props.crmEntityId = entityId;
        emailSettings.props.crmEntityId = entityId;

        meetingSettings.props.activityObj = instance;
        taskSettings.props.activityObj = instance;
        callSettings.props.activityObj = instance;
        emailSettings.props.activityObj = instance;
    }

    const settings =
        formType === 'createForm'
            ? {
                  Meeting: {
                      ...meetingSettings,
                      otherActivitiesModalSettings: {
                          Meeting: meetingSettings,
                          Task: taskSettings,
                          Call: callSettings,
                          'E-mail': emailSettings
                      }
                  },
                  Task: {
                      ...taskSettings,
                      otherActivitiesModalSettings: {
                          Meeting: meetingSettings,
                          Task: taskSettings,
                          Call: callSettings,
                          'E-mail': emailSettings
                      }
                  },
                  Call: {
                      ...callSettings,
                      otherActivitiesModalSettings: {
                          Meeting: meetingSettings,
                          Task: taskSettings,
                          Call: callSettings,
                          'E-mail': emailSettings
                      }
                  },
                  'E-mail': {
                      ...emailSettings,
                      otherActivitiesModalSettings: {
                          Meeting: meetingSettings,
                          Task: taskSettings,
                          Call: callSettings,
                          'E-mail': emailSettings
                      }
                  }
              }
            : {
                  Meeting: meetingSettings,
                  Task: taskSettings,
                  Call: callSettings,
                  'E-mail': emailSettings
              };

    return settings[activityType];
}
