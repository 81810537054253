<template v-if="name">
    <svg
        v-if="path"
        class="svg-icon"
        :class="{ 'base-icon': !$attrs.class }"
        :style="$attrs.style"
        :viewBox="iconViewBox"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="base-icon"
        stroke-width="1.5"
        v-html="processedPath"></svg>
    <inline-svg v-else :src="getItemPath(name)" width="24" height="24" aria-label="My image" data-testid="base-icon" :disableServerRequests="true"></inline-svg>
</template>

<script lang="ts" setup>
import { computed, defineProps, useAttrs } from 'vue';
import InlineSvg from 'vue-inline-svg';
import icons from '../../icons';

interface Props {
    name: keyof typeof icons;
    viewBox?: string;
    color?: string;
}

const props = defineProps<Props>();

const $attrs = useAttrs();
const getItemPath = (code: string): string => {
    return code ? `https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/${code}.svg` : '';
};
const path = computed(() => icons[props.name]);

const iconViewBox = computed(() => (props.viewBox ? props.viewBox : '0 0 24 24'));

const processedPath = computed(() => {
    let iconPath = path.value;

    if (props.color) {
        // Якщо fill відсутній, додаємо новий
        iconPath = iconPath.replace(/<path[^>]*>/g, match => {
            if (!match.includes('fill="') && !match.includes('stroke="')) {
                return match.replace('<path', `<path fill="${props.color}"`);
            }
            return match;
        });

        // Якщо fill є, але не 'none', замінюємо на новий колір
        iconPath = iconPath.replace(/fill="[^"]+"/g, match => {
            if (match !== 'fill="none"') {
                return `fill="${props.color}"`;
            }
            return match;
        });

        // Те саме для stroke
        iconPath = iconPath.replace(/stroke="[^"]+"/g, `stroke="${props.color}"`);
    }

    return iconPath;
});
</script>

<style lang="scss" scoped>
.active {
    transform: rotateZ(90deg);
}

.svg-icon {
    min-width: 14px;
}
</style>
