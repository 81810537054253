<template>
    <div v-show="modelValue">
        <VueDragResize
            class="bg-white custom-class"
            :class="{
                'rounded-[10px]': addedRadius && !isMobile
            }"
            :w="rect.width"
            :h="rect.height"
            :x="rect.left"
            :y="rect.top"
            :parentW="wrapperSize.width"
            :parentH="wrapperSize.height"
            :axis="rect.axis"
            :isActive="rect.active"
            :minw="rect.minw"
            :minh="rect.minh"
            :isDraggable="rect.draggable"
            :isResizable="rect.resizable"
            :parentLimitation="rect.parentLim"
            :snapToGrid="rect.snapToGrid"
            :gridX="gridX"
            :gridY="gridY"
            :aspectRatio="rect.aspectRatio"
            :z="rect.zIndex"
            :contentClass="rect.class"
            :stickSize="rect.stickSize"
            @activated="activateEv"
            @deactivated="deactivateEv"
            @dragging="changeDimensions($event)"
            @resizing="changeDimensions($event)"
            @componentApi="methods => emit('componentApi', methods)">
            <template #header>
                <slot name="header"></slot>
            </template>
            <slot></slot>
        </VueDragResize>
    </div>
</template>

<script lang="ts" setup>
import { computed, ComputedRef } from 'vue';
import { useUserStore } from '@/stores/userStore';

import VueDragResize from '@/components/ui/baseModalDragResize/components/VueDragResize.vue';

interface Stick {
    width?: number;
    height?: number;
    left?: number;
    top?: number;
    axis?: string;
    active?: boolean;
    minw?: number;
    minh?: number;
    draggable?: boolean;
    resizable?: boolean;
    parentLim?: boolean;
    snapToGrid?: boolean;
    gridX?: number;
    gridY?: number;
    aspectRatio?: boolean;
    zIndex?: number;
    class?: string;
    stickSize?: number;
    radius?: boolean;
    storeUserModalSize?: boolean;
    horizontalPosition?: string;
}

interface WrapperSize {
    width: number;
    height: number;
}

interface Props {
    modelValue?: boolean;
    rect?: Stick;
    wrapperSize?: WrapperSize;
    gridX?: number;
    gridY?: number;
}

const props = withDefaults(defineProps<Props>(), {
    modelValue: false,
    rect: () => ({}),
    wrapperSize: () => ({
        width: 0,
        height: 0
    }),
    gridX: 100,
    gridY: 100
});

const emit = defineEmits<{
    changeDimensions: [event: MouseEvent];
    activateEv: [];
    deactivateEv: [];
    componentApi: [methods: Record<string, Function>];
}>();

const { isMobile } = useUserStore();

const addedRadius: ComputedRef<boolean> = computed(() => {
    return !!props.rect?.radius;
});

const activateEv = (): void => {
    emit('activateEv');
};

const deactivateEv = (): void => {
    emit('deactivateEv');
};

const changeDimensions = (event: MouseEvent): void => {
    emit('changeDimensions', event);
};
</script>

<style lang="scss" scoped>
.custom-class {
    box-sizing: border-box;
    -webkit-box-shadow: 0px 0px 60px 0px #686c6f3d;
    -moz-box-shadow: 0px 0px 60px 0px #686c6f3d;
    box-shadow: 0px 0px 60px 0px #686c6f3d;

    overflow: hidden;
}
</style>
