<template>
    <div
        @dblclick="() => $emit('resizeFull')"
        @mousedown="event => headerMouseDown(event)"
        class="flex justify-between topmenu-container"
        :class="[{ 'border-b border-[#eaebee] border-solid': !backgroundHeader }, { 'bg-[linear-gradient(90.19deg,_#F0F3F8_0%,_#F4F6FA_100%)]': !isMobile }]"
        :style="{ background: backgroundHeader }">
        <div class="flex overflow-hidden">
            <div v-if="title" class="flex items-center h-full font-[Inter] ml-4 text-[#979bb2] align-center font-medium text-[15px] gap-1">
                <BaseIcon
                    v-if="icon"
                    :name="icon"
                    class="h-[24px] w-[24px]"
                    :style="{
                        fill: iconColor ?? '#3b82f6'
                    }"></BaseIcon>

                <span>{{ title }}</span>
            </div>

            <DynamicBreadcrumb
                v-if="showBreadcrumb && !title"
                :entityId="entityId"
                :colorText="colorFont"
                :instanceId="instanceId"
                :instanceObject="instanceObject"
                :visibleItem="visibleItem"
                :activeColor="activeColor"
                :cardScope="cardScope"></DynamicBreadcrumb>
        </div>

        <div class="flex">
            <CreateBasedEntityMenu v-if="cardScope?.cardType !== 'create'" :entityId="entityId" :instanceId="instanceId" />

            <div class="flex h-full">
                <nav-button
                    v-if="leftSideEntityMenuItems.length"
                    :items="leftSideEntityMenuItems"
                    :disabled="!viewsCountBtn"
                    :colorFont="colorFont"
                    @nav-btn-click="viewsCountBtnClick">
                    <template #suffix="{ item }">
                        <template v-if="item.id === 'viewsCount'">
                            <span class="font-normal text-sm font-['Inter'] ml-1" :class="[colorFont ? 'text-[' + colorFont + ']' : 'text-[#979BB2] ']">
                                {{ item.viewsCount }}
                            </span>
                        </template>
                    </template>

                    <SmartModal v-if="viewsCountBtnVisible" :target="viewsCountBtnEvent" @close="closeViewsCountMenu">
                        <div
                            ref="viewsListContainer"
                            class="w-full overflow-auto py-2.5 select-none divide-y divide-solid divide-[#EAEBEE] max-h-[244px] !py-0 scrollable-container">
                            <div v-for="(item, index) in viewsCountMenuItems" :key="index" class="flex justify-between py-1.5 px-4 font-normal items-center text-sm font-['Inter']">
                                <div class="flex items-center w-full overflow-hidden">
                                    <BaseAvatar class="" :user="item" :showInformation="false" :widthPic="36" :heightPic="36" @click="showUserInformation(item.id)" />
                                    <span class="ml-2.5 text-[#131416] whitespace-nowrap text-ellipsis overflow-hidden">
                                        {{ item.name }}
                                    </span>
                                </div>

                                <p class="ml-2.5 text-[#979BB2] whitespace-nowrap">
                                    {{ item.datetime }}
                                </p>
                            </div>
                        </div>
                    </SmartModal>
                </nav-button>

                <div v-if="leftSideEntityMenuItems.length" class="my-[5px] mr-2 border-r border-[#EAEBEE]"></div>

                <nav-button
                    v-if="entityMenuFilteread.length"
                    :items="entityMenuFilteread"
                    :colorFont="colorFont"
                    class="nav-btn modal-top-menu-icons cursor-auto my-2"
                    @nav-btn-click="navBtnClick"
                    :disabled="!moreBtn"
                    :showBreadcrumb="showBreadcrumb">
                    <ContextMenu
                        v-if="moreBtnVisible"
                        :event="moreBtnEvent"
                        :items="moreMenuItems"
                        ref="moreBtnContextMenuRef"
                        @changedValue="moreBtnSubmenuClick"
                        @closeContextMenu="closeMoreMenu"></ContextMenu>

                    <SmartModal v-if="isMyPlanStagesContextMenuOpened" :target="сurrentMyPlanStageBtnEvent.target" @close="closeMyPlanCurrentStageMenu">
                        <div ref="myPlanStagesContextMenuRef" class="flex flex-col py-2.5 px-4 scroll-wrap cursor-pointer max-h-[350px]">
                            <template v-for="item in currentStageInMyPlanMenuItems" :key="item.id">
                                <template v-if="item.type === 'button'">
                                    <BaseButton
                                        v-if="item.variant === 'base'"
                                        :color="item.color"
                                        :label="item.label"
                                        size="sm"
                                        class="mx-4"
                                        @click.prevent="сurrentMyPlanStageBtnContextMenuOptionClick({ id: item.id })"></BaseButton>
                                </template>

                                <template v-if="item.type === 'other'">
                                    <div
                                        class="flex h-[42px] items-center relative shrink-0 relative"
                                        :class="{ 'pr-8': item.isSelected }"
                                        @click="сurrentMyPlanStageBtnContextMenuOptionClick({ id: item.id })">
                                        <div class="mr-1">
                                            <BaseIcon name="Big_dot" class="h-[24px] w-[24px]" :style="{ fill: item.iconColor }"></BaseIcon>
                                        </div>
                                        <div class="text-[#141414] hover:text-[--primary]">{{ item.label }}</div>
                                        <div v-if="item.isSelected" class="absolute right-0 top-1/2 -translate-y-1/2">
                                            <BaseIcon name="tick-02" class="h-[24px] w-[24px] fill-[--primary]"></BaseIcon>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                    </SmartModal>
                </nav-button>
            </div>
        </div>
    </div>
</template>

<script>
import { toRaw } from 'vue';
import { mapActions, mapState } from 'pinia';

import BaseAvatar from '@/components/ui/BaseAvatar.vue';
import NavButton from '@/components/platformSlider/NavButton.vue';
import ContextMenu from '@/components/ui/ContextMenu/ContextMenu.vue';
import CreateBasedEntityMenu from '@/components/basedEntities/CreateBasedEntityMenu.vue';
import DynamicBreadcrumb from '@/components/platformBreadcrumb/DynamicBreadcrumb.vue';
import SmartModal from '@/components/ui/SmartModal.vue';
import BaseButton from '@/components/ui/baseButton/BaseButton.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';

import { useUserStore } from '@/stores/userStore';

import FavoriteApi from '@/api/favorite';
import { sendRequest } from '@/helpers';

export default {
    name: 'ModalTopMenu',
    components: {
        BaseIcon,
        BaseButton,
        SmartModal,
        BaseAvatar,
        CreateBasedEntityMenu,
        NavButton,
        DynamicBreadcrumb,
        ContextMenu
    },
    props: {
        entityId: {
            type: String
        },
        instanceId: {
            type: String
        },
        instanceObject: {
            type: Object
        },
        moreBtn: {
            type: Object,
            default: () => ({})
        },
        myPlanCurrentStageBtn: {
            type: Object,
            default: () => ({})
        },
        viewsCountBtn: {
            type: Object,
            default: () => ({})
        },
        target: {
            type: String,
            default: 'editCard'
        },
        componentMounted: {
            type: Boolean,
            default: false
        },
        useInFavorite: {
            type: Boolean,
            default: false
        },
        useCopyLink: {
            type: Boolean,
            default: true
        },
        showBreadcrumb: {
            type: Boolean,
            default: true
        },
        visibleItem: {
            type: Object
        },
        isFavorite: {
            type: [Boolean, String]
        },
        activeColor: {
            type: String,
            default: '#6e6e6e'
        },
        title: {
            type: String,
            default: ''
        },
        icon: {
            type: String
        },
        iconColor: {
            type: String
        },
        cardScope: {
            type: Object,
            default: () => ({})
        }
    },
    emits: ['btnClick', 'removeFromFavorite', 'resizeFull', 'closeContextMenu'],
    data() {
        return {
            favoriteId: null,

            moreBtnVisible: false,
            moreBtnContextMenuWidth: undefined,
            viewsCountBtnVisible: false,
            isMyPlanStagesContextMenuOpened: false,

            isMyPlanStagesAccordionOpened: false,

            moreBtnEvent: null,
            viewsCountBtnEvent: null,
            сurrentMyPlanStageBtnEvent: null,

            viewsCountBtnActiveStatus: false,

            additionNemu: null,
            entetyMenu: {},

            isCopyLinkBtnActive: false,
            isInFavoriteBtnActive: false,
            isMoreBtnActive: false,

            backgroundHeader: '',
            colorFont: ''
        };
    },
    computed: {
        ...mapState(useUserStore, ['user', 'isAdmin', 'topMenuStyles', 'isMobile']),

        isCopyLinkBtnHidden() {
            return !this.useCopyLink;
        },
        isInFavoriteBtnHidden() {
            return !this.useInFavorite || this.isFavorite === undefined;
        },
        isMoreBtnHidden() {
            return !this.moreBtn?.list?.length || !Object.keys(this.moreBtn).length;
        },
        menuItems() {
            const menu = [
                {
                    id: 'copyLink',
                    icon: 'link-04',
                    viewBox: '0 0 24 24',
                    active: this.isCopyLinkBtnActive,
                    tooltip: this.$t('menu.copyLink'),
                    hide: this.isCopyLinkBtnHidden
                },
                {
                    id: 'star',
                    icon: 'bookmark-add-02',
                    active: this.isInFavoriteBtnActive,
                    viewBox: '0 0 24 24',
                    tooltip: this.$t('menu.toFavorite'),
                    hide: this.isInFavoriteBtnHidden
                },
                {
                    id: 'more',
                    icon: 'dotsHorizontal',
                    viewBox: '0 0 24 24',
                    active: this.isMoreBtnActive,
                    tooltip: this.$t('menu.more'),
                    hide: this.isMoreBtnHidden
                },
                {
                    id: 'hide',
                    icon: 'tray_24px',
                    viewBox: '0 0 24 24',
                    active: false,
                    tooltip: this.$t('menu.hide'),
                    hide: false
                },
                {
                    id: 'close',
                    icon: 'clouse',
                    viewBox: '0 0 24 24',
                    active: false,
                    tooltip: this.$t('menu.close'),
                    hide: false
                }
            ];

            if (this.myPlanCurrentStageBtn?.list?.length) {
                menu.unshift({
                    id: this.myPlanCurrentStageBtn.id,
                    icon: this.myPlanCurrentStageBtn.icon,
                    viewBox: '0 0 24 24',
                    active: false,
                    iconColor: this.myPlanCurrentStageBtn.iconColor,
                    tooltip: this.myPlanCurrentStageBtn.label || this.$t('menu.showMyPlanListContextMenuOptionBtn'),
                    hide: false
                });
            }

            return menu;
        },

        myPlanStagesId() {
            return this.myPlanCurrentStageBtn?.list?.map(({ id }) => id) || [];
        },

        leftSideEntityMenuItems() {
            const menuList = [];
            if (this.viewsCountBtn?.viewsCount !== undefined) {
                menuList.push({
                    id: this.viewsCountBtn.id,
                    viewsCount: this.viewsCountBtn.viewsCount,
                    icon: this.viewsCountBtn.icon,
                    viewBox: '0 0 24 24',
                    active: this.viewsCountBtnActiveStatus,
                    tooltip: this.$t('menu.viewsCount'),
                    hide: !this.viewsCountBtn
                });
            }
            return menuList;
        },

        entityMenuFilteread() {
            return this.menuItems.filter(el => !el.hide);
        },
        isNewInstance() {
            return this.instanceId === '-1' || this.instanceId === '-2';
        },
        moreMenuItems() {
            if (this.moreBtn?.list) {
                return this.moreBtn.list.map(item => {
                    if (item.type === 'accordion') {
                        return { ...item, width: this.moreBtnContextMenuWidth };
                    }
                    return item;
                });
            }
            return [];
        },
        viewsCountMenuItems() {
            return this.viewsCountBtn?.list || [];
        },
        currentStageInMyPlanMenuItems() {
            return this.myPlanCurrentStageBtn?.list || [];
        }
    },
    methods: {
        ...mapActions(useUserStore, ['getTopMenuStyle']),
        // generateAvailableEntetyMenuItem() {
        //   if (this.moreBtn) {
        //     let aditionalMenu = [];
        //     this.moreBtn?.list?.forEach((element) => {
        //       if (element.id === "delete") {
        //         if (this.can("delete", "task")) {
        //           aditionalMenu.push(element);
        //         }
        //       } else aditionalMenu.push(element);
        //     });
        //     return aditionalMenu;
        //   } else return [];
        // },

        showUserInformation(userId) {
            if (userId) {
                this.$modal.open({
                    id: userId,
                    name: 'UserInformation',
                    useInFavorite: false,
                    useCopyLink: false,
                    props: {
                        userId
                    }
                });

                this.viewsCountBtnVisible = false;
            }
        },

        setLastTaskViewerObserver(containerRef) {
            const items = containerRef?.children || [];
            let lastItem = null;

            if (items) lastItem = items[items?.length - 1];

            const options = {
                root: null,
                rootMargin: '0px 0px 0px 0px',
                threshold: 0
            };

            const lastItemObserver = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        this.$emit('btnClick', 'viewsCount');
                        lastItemObserver.unobserve(entry.target);
                    }
                });
            }, options);

            if (lastItem) lastItemObserver.observe(lastItem);
        },
        headerMouseDown(event) {
            if (event.button === 1) {
                this.$emit('btnClick', 'close');
            }
        },
        moreBtnSubmenuClick(submenuItem) {
            const { id } = submenuItem;

            if (id !== 'close') {
                if (id === 'addNewMyPlanStageContextSubmenuBtn') {
                    this.sendAnalytics('addNewMyPlanStageContextMenuBtn');
                    this.$emit('btnClick', id);
                    return;
                }
                if (id === 'showMyPlanListContextMenuOptionBtn') {
                    this.sendAnalytics('showMyPlanListContextMenuOptionBtn');
                    this.isMyPlanStagesAccordionOpened = !this.isMyPlanStagesAccordionOpened;
                    if (this.isMyPlanStagesAccordionOpened) {
                        this.$emit('btnClick', id);
                    }
                    return;
                }
                if (this.myPlanStagesId.includes(id)) {
                    this.sendAnalytics('setMyPlanStage');
                    this.$emit('btnClick', id);
                    return;
                } else {
                    this.$emit('btnClick', id);
                }

                this.closeMoreMenu();
            }
        },
        сurrentMyPlanStageBtnContextMenuOptionClick(submenuItem) {
            const { id } = submenuItem;

            switch (id) {
                case 'addNewMyPlanStageContextMenuBtn': {
                    this.sendAnalytics('addNewMyPlanStageContextMenuBtn');
                    break;
                }
                default: {
                    this.sendAnalytics('setMyPlanStage');
                }
            }

            this.$emit('btnClick', id);
            this.isMyPlanStagesContextMenuOpened = false;
        },
        viewsCountBtnClick(entityId, event) {
            if (entityId === 'viewsCount') {
                this.sendAnalytics('viewsCount');
                if (this.viewsCountBtn) {
                    this.openViewsCountMenu(event);
                }
            }
        },
        navBtnClick(e, event) {
            if (e === 'close') {
                this.sendAnalytics('closeForm');
                this.$emit('btnClick', e);
                return;
            }
            if (e === 'copyLink') {
                this.sendAnalytics('saveLink');
                this.$emit('btnClick', e);
                return;
            }
            if (e === 'hide') {
                this.sendAnalytics('hide');
                this.$emit('btnClick', e);
                return;
            }
            if (e === 'showMyPlanListNavbarBtn') {
                this.sendAnalytics('showMyPlanListContextMenuOptionBtn');
                this.$emit('btnClick', e);
                if (this.myPlanCurrentStageBtn) {
                    this.openCurrentStageInMyPlanMenu(event);
                }
                return;
            }
            if (!this.isNewInstance) {
                switch (e) {
                    case 'more':
                        this.sendAnalytics('moreFunc');
                        if (this.moreBtn) {
                            this.openMoreMenu(event);
                        }
                        break;
                    case 'star':
                        this.sendAnalytics('addToFav');
                        if (!this.favoriteId) {
                            this.addEntityToFavorite();
                        } else {
                            this.removeEntityFromFavorite();
                        }
                        break;
                    default:
                        this.moreBtnSubmenuClick(e);
                        break;
                }
            }
        },
        async openMoreMenu(event) {
            if (this.moreBtnVisible) {
                this.moreBtnVisible = false;
            } else {
                this.moreBtnEvent = event;
                // await this.getInstanceAbility();
                // this.entetyMenu.list = this.generateAvailableEntetyMenuItem();
                // await this.loadAdditionMenu();
                this.moreBtnVisible = true;
                await this.$nextTick();
                this.moreBtnContextMenuWidth = this.$refs.moreBtnContextMenuRef?.getWidth();
            }
        },
        async openViewsCountMenu(event) {
            this.viewsCountBtnEvent = event.target;
            this.viewsCountBtnVisible = true;
            this.viewsCountBtnActiveStatus = true;
        },
        async openCurrentStageInMyPlanMenu(event) {
            const idx = this.menuItems.findIndex(({ id }) => id === 'showMyPlanListNavbarBtn');
            this.сurrentMyPlanStageBtnEvent = event;
            this.isMyPlanStagesContextMenuOpened = true;
            this.menuItems[idx].active = true;
        },
        closeMoreMenu() {
            this.moreBtnVisible = false;
            this.$emit('closeContextMenu');
        },
        closeViewsCountMenu() {
            this.viewsCountBtnVisible = false;
            this.viewsCountBtnActiveStatus = false;
            this.$emit('closeContextMenu');
        },
        closeMyPlanCurrentStageMenu() {
            this.isMyPlanStagesContextMenuOpened = false;
            const idx = this.menuItems.findIndex(({ id }) => id === 'showMyPlanListNavbarBtn');
            this.menuItems[idx].active = false;
            this.$emit('closeContextMenu');
        },
        async checkForFavorite() {
            if (this.entityId && this.instanceId && !this.instanceId.includes('-1') && this.instanceId != '-2') {
                let res = await FavoriteApi.checkIfInstanseInFavorite(this.entityId, this.instanceId);

                if (res?.id) {
                    this.favoriteId = res.id;
                    this.isInFavoriteBtnActive = true;
                } else {
                    this.favoriteId = null;
                    this.isInFavoriteBtnActive = false;
                }
            }
        },
        async addEntityToFavorite() {
            if (this.entityId && this.instanceId) {
                let favorite = {
                    entity_id: this.entityId,
                    instance_id: this.instanceId,
                    user_id: toRaw(this.user.id)
                };
                let res = await FavoriteApi.addToFavorite(favorite);
                if (res) {
                    this.favoriteId = res.id;
                    this.isInFavoriteBtnActive = true;
                    this.$emit('btnClick', { event: 'addToFavorite', isFavorite: res?.id, instanceId: this.instanceId });
                }
            }
        },
        async removeEntityFromFavorite() {
            FavoriteApi.removeFromFavorite(this.favoriteId);
            this.favoriteId = null;
            this.isInFavoriteBtnActive = false;
            this.$emit('removeFromFavorite', this.instanceId);
            this.$emit('btnClick', { event: 'removeFromFavorite', isFavorite: null, instanceId: this.instanceId });
        },
        async loadAdditionMenu() {
            if (this.isAdmin && !this.isNewInstance) {
                this.additionNemu = await sendRequest(`/api/entity_actions/${this.entityId}`, 'get', '');
            }
        },
        async additionalMenuItemClick(item) {
            let req = {
                parameters: {
                    instance_id: this.instanceId
                }
            };
            let res = await sendRequest(`api/code_run/execute/${item.id}`, 'post', req);
            if (res) {
                this.$notify({ type: 'success', title: 'Success', text: 'Code run success' }, 2000);
            }
            console.log(res);
        },
        // async getInstanceAbility() {
        //   if (
        //     this.instanceObject &&
        //     hasProperty(this.instanceObject, "permissions")
        //   ) {
        //     this.setAbility(this.instanceObject?.permissions);
        //   } else {
        //     //  console.warn("no instanceObject in menu", this.instanceObject);
        //     if (this.isNewInstance) {
        //       return;
        //     }
        //     let instanceObj = await EntityApi.getInstance(
        //       this.entityId,
        //       this.instanceId
        //     );
        //     this.setAbility(instanceObj?.permissions);
        //   }
        // },
        async initFavorites() {
            if (this.useInFavorite) {
                if (this?.isFavorite === undefined) {
                    await this.checkForFavorite();
                } else {
                    this.favoriteId = this.isFavorite;
                    this.isInFavoriteBtnActive = !!this.isFavorite;
                }
            }
        },
        sendAnalytics(eventKey) {
            const currentTarget = localStorage.getItem('taskCardTarget');
            const targetCategories = {
                modal: 'In the modal window',
                rightSide: 'Slider window Top menu',
                fullPage: 'Full window Top menu',
                newTab: 'New tab top menu'
            };
            if (!(currentTarget in targetCategories)) {
                return;
            }

            const labelsData = {
                addNewMyPlanStageContextMenuBtn: 'Open a creation form for the new My plan stage',
                hide: 'Hide modal form',
                showMyPlanListContextMenuOptionBtn: 'Add task to My plan',
                setMyPlanStage: 'Add task in stage of My plan',
                closeForm: 'Close modal form',
                saveLink: 'Save link to clipboard',
                moreFunc: 'Open list of more functions',
                addToFav: 'Add to favorite'
            };
            if (!(eventKey in labelsData)) {
                return;
            }
            this.$analytics.addEvent({
                category: targetCategories[currentTarget],
                action: 'lmbc',
                label: labelsData[eventKey],
                entity: this.entityId,
                instance: this.instanceId
            });
        },
        detectColor() {
            let style = this.getTopMenuStyle(this.instanceId);
            if (style?.background) {
                this.backgroundHeader = style.background;
            } else {
                this.backgroundHeader = '';
            }

            if (style?.color) {
                this.colorFont = style.color;
            } else {
                this.colorFont = '';
            }
        }
    },
    watch: {
        async instanceId() {
            if (this.instanceId !== '-2' && this.instanceId !== '-1') {
                await this.initFavorites();
            }
            this.detectColor();
        },
        topMenuStyles: {
            handler() {
                this.detectColor();
            },
            deep: true
        },
        componentMounted: {
            handler(newValue) {
                if (newValue === true) {
                    this.initFavorites();
                }
            },
            immediate: true
        }
    },
    created() {},
    sockets: {
        favorite_instance(payload) {
            if (payload.data && this.useInFavorite && this.instanceId && payload.data.instance_id === this.instanceId) {
                if (payload?.event === 'insert' && !this.favoriteId) {
                    this.isInFavoriteBtnActive = true;
                    this.favoriteId = payload.data?.id;
                    return;
                }

                if (payload?.event === 'delete' && this.favoriteId) {
                    this.isInFavoriteBtnActive = false;
                    this.favoriteId = null;
                    return;
                }
            }
        }
    },
    mounted() {
        if (this.viewsCountBtn?.viewsCount !== undefined) {
            this.setLastTaskViewerObserver(this.$refs.viewsListContainer);
        }
    },
    updated() {
        if (this.viewsCountBtn?.viewsCount !== undefined) {
            this.setLastTaskViewerObserver(this.$refs.viewsListContainer);
        }
    },
    unmounted() {}
};
</script>

<style lang="scss" scoped>
/*.topmenu-container {
    border-bottom: 1px solid rgb(229, 231, 235);
}*/
.modal-top-menu-icons {
    margin: 0 !important;
    height: 35px !important;
}
.scrollable-container {
    scrollbar-width: auto;
    &::-webkit-scrollbar-thumb {
        background-color: #eaebee;
        border-radius: 40px;
    }
    &::-webkit-scrollbar {
        width: 8px;
        border-radius: 3px;
    }
}
</style>
