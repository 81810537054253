<template>
    <div class="btn__wrapper flex justify-center items-center">
        <div class="btn__list flex w-full" v-if="items">
            <template v-for="item in items" :key="item.id">
                <div class="btn__item platform-focusable flex items-center">
                    <div
                        class="flex items-center"
                        :class="{
                            active: !item.iconColor && item.active
                        }"
                        role="button"
                        @click="eventClick(item.id, $event)"
                        @touchend.prevent="eventClick(item.id, $event)"
                        :data-action="item.id"
                        v-tooltip:bottom-end="item.tooltip">
                        <span v-if="item.label">{{ item.label }}</span>
                        <base-icon
                            :viewBox="item.viewBox || '0 0 20 20'"
                            class="button__icon"
                            :class="item.id"
                            :name="itemIcon(item)"
                            :style="{
                                fill: colorFont ? colorFont : item.iconColor,
                                color: colorFont ? colorFont : item.iconColor
                            }"></base-icon>
                    </div>
                    <slot name="suffix" :item="item" />
                </div>
            </template>
        </div>
        <slot></slot>
    </div>
</template>

<script>
import BaseIcon from '@/components/ui/BaseIcon.vue';

export default {
    name: 'nav-button',
    props: {
        items: {
            type: Array,
            required: true
        },
        disabled: {
            type: [Boolean, Object],
            required: false
        },
        colorFont: {
            type: String
        }
    },
    components: {
        BaseIcon
    },

    methods: {
        eventClick(item, event) {
            this.$emit('nav-btn-click', item, event);
        },
        itemIcon(item) {
            // return item?.active ? `${item.icon}_active` : item.icon;
            return item.icon;
        }
    }
};
</script>

<style lang="scss" scoped>
.btn__wrapper {
    position: relative;
    float: right;

    // margin-top: 8px;
    // padding-left: 30px;
    padding-left: 8px;
    padding-right: 8px;
}
.btn__list {
    align-items: center;
    margin-right: 8px;
    padding-bottom: 5px;
    padding-top: 5px;
}

.btn__item {
    position: relative;
    cursor: pointer;
    fill: var(--gray2);
    color: var(--gray2);
    transition: all var(--sp-transition-duration, 250ms) var(--sp-transition-function, cubic-bezier(0.4, 0, 0.2, 1));

    &:hover,
    &:focus {
        fill: var(--sp-primary, #2693ff);
        color: var(--sp-primary, #2693ff);
    }
}

.btn__item + .btn__item {
    margin-left: 1rem;
}

.btn__list .button__icon {
    pointer-events: none;
    width: 1.5rem;
    height: 1.5rem;

    &:hover,
    &:focus {
        fill: var(--primary);
        color: var(--primary);
    }
}
.active {
    // background: rgba(55, 53, 47, 0.08);
    fill: var(--primary);
    color: var(--primary);
}
.active .button__icon {
    fill: var(--primary);
    color: var(--primary);
}
</style>
