<template>
    <label class="base-toggle flex items-center w-full py-2.5" :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'">
        <span class="switch-label" :class="{ 'mr-2': label }">{{ label }}</span>
        <div class="switch" :class="{ disabled: props.disabled }">
            <input type="checkbox" :disabled="props.disabled" :checked="internalValue" @change="updateValue" />
            <span class="slider" :class="'slider-' + color"></span>
        </div>
    </label>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { uuid } from 'vue-uuid';

const props = defineProps({
    label: String,
    modelValue: {
        type: [Boolean, String, Number],
        default: null
    },
    value: {
        type: [Boolean, String, Number],
        default() {
            return null;
        }
    },
    id: {
        type: String,
        default: () => uuid.v4()
    },
    size: {
        type: Number,
        default: 16
    },
    color: {
        type: String,
        default: 'var(--sp-primary)'
    },
    disabled: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['update:modelValue', 'toggle-checked', 'change']);
const internalValue = computed(() => props.modelValue || props.value);
const updateValue = () => {
    if (props.disabled) return;
    emit('toggle-checked', !internalValue.value, props.id);
    emit('update:modelValue', !internalValue.value);
    emit('change', !internalValue.value);
};
</script>

<style scoped lang="scss">
/* @import "@/components/ui/baseSwitch/BaseSwitch.scss"; */
$icon-unchecked: 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M9.27007 2.72962C9.51416 2.97371 9.51415 3.36942 9.27007 3.6135L3.61321 9.27036C3.36912 9.51445 2.97342 9.51445 2.72933 9.27036C2.48524 9.02626 2.48524 8.63057 2.72933 8.38647L8.38618 2.72962C8.63026 2.48554 9.02597 2.48553 9.27007 2.72962Z" fill="%23979BB2"/%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M2.72944 2.72962C2.97354 2.48553 3.36925 2.48554 3.61333 2.72962L9.27018 8.38647C9.51428 8.63057 9.51428 9.02626 9.27018 9.27036C9.02609 9.51445 8.63039 9.51445 8.3863 9.27036L2.72944 3.6135C2.48537 3.36942 2.48535 2.97371 2.72944 2.72962Z" fill="%23979BB2"/%3E%3C/svg%3E';
$icon-checked: 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M9.84525 2.88833C10.045 3.079 10.0524 3.39549 9.8617 3.59524L4.61168 9.09525C4.51872 9.1926 4.39043 9.2484 4.25582 9.24995C4.1212 9.25155 3.99164 9.19875 3.89645 9.10355L2.14645 7.35355C1.95119 7.1583 1.95119 6.8417 2.14645 6.64645C2.34171 6.4512 2.65829 6.4512 2.85355 6.64645L4.24168 8.0346L9.1383 2.90477C9.329 2.70502 9.6455 2.69766 9.84525 2.88833Z" fill="%23519DF5"/%3E%3C/svg%3E';

.switch {
    position: relative;
    display: inline-block;
    width: 36px;
    min-width: 36px;
    height: 20px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: 0.4s;
        border-radius: 12px;
        background-color: #eaebee;

        &:before {
            position: absolute;
            content: '';
            height: 16px;
            width: 16px;
            left: 2px;
            bottom: 2px;
            background-color: #fcfcfd;
            transition:
                transform 0.4s ease,
                opacity 0.4s ease;
            border-radius: 50%;
            box-shadow: 0px 1px 2px 0px #1018280f;
            box-shadow: 0px 1px 3px 0px #1018281a;
            background-image: url(#{$icon-unchecked});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 12px;
        }
    }

    input:checked + .slider {
        background-color: #519df5;

        &:before {
            transform: translateX(16px);
            background-image: url(#{$icon-checked});
            background-color: #ffffff;
        }
        &-danger {
            background-color: #ff7c73;
        }
        &-warning {
            background-color: #fbc12e;
        }
        &-success {
            background-color: #55c47b;
        }
    }
    &.disabled {
        .slider {
            background-color: #f9f9f9;
            cursor: not-allowed;
        }
        input:checked + .slider {
            background-color: #519df533;
        }
    }
}
</style>
