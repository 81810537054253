<template>
    <div
        class="context-menu__element flex justify-between items-center gap-3"
        :class="[{ 'context-menu__element_readonly': props.data.readonly }, { 'context-menu__element_checked': props.data.value }]"
        @click="!props.data.readonly ? toggleValue(props.data) : {}">
        <div class="flex gap-1 items-center">
            <div
                v-if="props.data.icon"
                class="context-menu__element__icon flex items-center justify-center rounded-full"
                :style="{
                    height: `${props.iconWrapperHeight}px`,
                    width: `${props.iconWrapperHeight}px`,
                    background: props.data.iconBgColor ? props.data.iconBgColor : ''
                }">
                <base-icon
                    v-if="props.data.icon"
                    :style="{
                        height: `${props.iconHeight}px`,
                        fill: props.data.iconColor && props.data.value ? props.data.iconColor : ''
                    }"
                    :name="props.data.icon"
                    class="context-menu__element-icon"></base-icon>
            </div>
            <span v-if="props.data.label">
                {{ props.data.label }}
            </span>
        </div>
        <BaseToggle :value="props.data.value" :disabled="props.data.readonly" class="pointer-events-none" />
    </div>
</template>

<script setup>
import { inject } from 'vue';
import { InjectionKeyToggleValue } from '../keys.js';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import BaseToggle from '@/components/ui/BaseToggle.vue';

const props = defineProps({
    data: {
        type: Object,
        default: () => {}
    },
    iconHeight: {
        type: Number,
        default: 24
    }
});
const toggleValue = inject(InjectionKeyToggleValue);
</script>
<style scoped>
.base-toggle {
    width: fit-content !important;
}
</style>
