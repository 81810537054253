<template>
    <span
        class="platform-badge flex shrink-0 items-center justify-center text-[#FFFFFF] rounded-full px-0.5 cursor-default select-none"
        :style="[sizeToPx(props.size), colorToBackground(props.color)]">
        <slot></slot>
    </span>
</template>

<script setup lang="ts">
import { BaseComponentSizeModel } from '@/models/base-component-size.model';
import { PropType } from 'vue';

type BadgeColor = 'primary' | 'danger' | 'success' | 'warning';

const props = defineProps({
    size: {
        type: String as PropType<BaseComponentSizeModel>,
        default: 'md',
        validator: (prop: string) => ['sm', 'md', 'lg'].includes(prop)
    },
    color: {
        type: String as PropType<BadgeColor>,
        default: 'primary',
        validator: (prop: string) => ['primary', 'danger', 'success', 'warning'].includes(prop)
    }
});

interface StyleObject {
    minWidth?: string;
    minHeight?: string;
    height?: string;
    fontSize?: string;
    fontWeight?: number;
    lineHeight?: string;
    background?: string;
}

const sizeToPx = (size: BaseComponentSizeModel): StyleObject => {
    switch (size) {
        case 'sm':
            return {
                minWidth: '18px',
                minHeight: '18px',
                height: '16px',
                fontSize: '0.63rem', //10px
                fontWeight: 700,
                lineHeight: '28px'
            };
        case 'md':
        case 'lg':
            return {
                minWidth: '24px',
                minHeight: '24px',
                height: '24px',
                fontSize: '13px',
                fontWeight: 700,
                lineHeight: '15.73px'
            };
        default:
            return { minWidth: '16px', minHeight: '16px' };
    }
};

const colorToBackground = (color: 'primary' | 'danger' | 'success' | 'warning'): StyleObject => {
    switch (color) {
        case 'primary':
            return {
                background: '#519DF5'
            };
        case 'danger':
            return {
                background: '#FF7C73'
            };
        case 'success':
            return {
                background: '#55C47B'
            };
        case 'warning':
            return {
                background: '#FBC12E'
            };
        default:
            return {
                background: '#519DF5'
            };
    }
};
</script>

<style lang="scss" scoped></style>
