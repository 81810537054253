import { isPrimitive, hasProperty, valueType } from './common';
import dayjs from 'dayjs';

import isoWeek from 'dayjs/plugin/isoWeek';
dayjs.extend(isoWeek);

let transformConjunction = cond => {
    switch (cond) {
        case 'and':
            return '&&';
        case 'or':
            return '||';
        default:
            break;
    }
};
let generateCondition = (conditionObg, targetSample) => {
    if (conditionObg.disabled) {
        return true;
    }
    if (conditionObg.field === '#has_badges') {
        conditionObg.field = 'has_badges';
    }
    if (
        conditionObg.field == '' ||
        conditionObg.value === '' ||
        conditionObg.value === undefined ||
        conditionObg.value === null ||
        !hasProperty(targetSample, conditionObg.field)
    ) {
        // conditionObg.field === '#has_badges' ||
        return true;
    }
    if (!Object.prototype.hasOwnProperty.call(conditionObg, 'value') || conditionObg.value == null) {
        // console.log('value is null or not set');
        switch (conditionObg.comparator) {
            case 'empty':
                return `(!target.${conditionObg.field} || target.${conditionObg.field} == null || target.${conditionObg.field} == 'null' || target?.${conditionObg.field}?.length == 0)`;
            case 'not empty':
                return `(target?.${conditionObg.field} !== null && target?.${conditionObg.field} !== 'null' && target?.${conditionObg.field}?.length !== 0)`;
        }
    }

    if (typeof conditionObg.value === 'boolean') {
        // console.log('value is boolean');
        switch (conditionObg.comparator) {
            case '=':
                return `(target?.${conditionObg.field} === ${conditionObg.value})`;
            case '<>':
                return `(target?.${conditionObg.field} !== ${conditionObg.value})`;
            default:
                console.warn('local filter error, value is boolean');
                return true;
        }
    }

    if (isPrimitive(conditionObg.value)) {
        let validStr = String(conditionObg.value)?.replaceAll("'", '');
        // console.log('value is Primitive');
        const dateObj = getDatesByPeriod(conditionObg.value);
        switch (conditionObg.comparator) {
            case '=':
                return `(target?.${conditionObg.field} == '${conditionObg.value}')`;
            case '<>':
                return `(target?.${conditionObg.field} != '${conditionObg.value}')`;
            case '>':
                return `(target?.${conditionObg.field} > '${conditionObg.value}')`;
            case '>=':
                return `(target?.${conditionObg.field} >= '${conditionObg.value}')`;
            case '<':
                return `(target?.${conditionObg.field} < '${conditionObg.value}')`;
            case '<=':
                return `(target?.${conditionObg.field} <= '${conditionObg.value}')`;
            case 'like':
                return `(target?.${String(conditionObg.field)}?.replaceAll("'", "")?.toUpperCase()?.includes('${validStr}'?.toUpperCase()))`;
            case 'period':
                return `(target?.${conditionObg.field} >= '${dateObj.start}' && target?.${conditionObg.field} <= '${dateObj.end}')`;

            default:
                console.warn('local filter error, value is Primitive');
                return true;
        }
    } else if (Array.isArray(conditionObg.value)) {
        // console.log('values is Array of value', targetSample[conditionObg?.field][0]);
        // if(!targetSample[conditionObg?.field] || !Array.isArray(targetSample[conditionObg?.field]) || targetSample[conditionObg?.field]?.length > 1){
        //   return false
        // }
        let targetValueType = valueType(targetSample[conditionObg?.field]);
        let strVal = '';
        if (isPrimitive(conditionObg.value?.[0])) {
            strVal = conditionObg.value;
        } else {
            strVal = conditionObg.value.map(el => el.id);
        }
        switch (conditionObg.comparator) {
            case 'in list':
                if (targetValueType === 'primitive') {
                    return `window.compareArraysContainsAnyValues([target.${conditionObg.field}], ${JSON.stringify(strVal)})`;
                } else if (targetValueType === 'array') {
                    if (isPrimitive(targetSample[conditionObg?.field][0])) {
                        return `window.compareArraysContainsAnyValues(target.${conditionObg.field}?.map(el => el), ${JSON.stringify(strVal)})`;
                    } else {
                        return `window.compareArraysContainsAnyValues(target.${conditionObg.field}?.map(el => el?.id), ${JSON.stringify(strVal)})`;
                    }
                } else if (targetValueType === 'object') {
                    return `window.compareArraysContainsAnyValues([target.${conditionObg.field}?.id], ${JSON.stringify(strVal)})`;
                } else return null;
            case 'not in list':
                if (targetValueType === 'primitive') {
                    return `!window.compareArraysContainsAnyValues([target.${conditionObg.field}], ${JSON.stringify(strVal)})`;
                } else if (targetValueType === 'array') {
                    if (isPrimitive(targetSample[conditionObg?.field][0])) {
                        return `!window.compareArraysContainsAnyValues(target.${conditionObg.field}?.map(el => el), ${JSON.stringify(strVal)})`;
                    } else {
                        return `!window.compareArraysContainsAnyValues(target.${conditionObg.field}?.map(el => el?.id), ${JSON.stringify(strVal)})`;
                    }
                } else if (targetValueType === 'object') {
                    return `!window.compareArraysContainsAnyValues([target.${conditionObg.field}?.id], ${JSON.stringify(strVal)})`;
                } else return null;
            default:
                break;
        }
    } else {
        // console.log('values is Object');
        switch (conditionObg.comparator) {
            case '=':
                return `(target?.${conditionObg.field}?.id === '${conditionObg.value?.id}')`;
            case '<>':
                return `(target?.${conditionObg.field}?.id !== '${conditionObg.value?.id}')`;
            case 'between':
                return `(target?.${conditionObg.field} >= '${conditionObg.value.start}' && target?.${conditionObg.field} <= '${conditionObg.value.end}')`;
            case 'in list':
                return `window.compareArraysContainsAnyValues(target.${conditionObg.field}?.map(el => el?.id), [${JSON.stringify(conditionObg.value?.id)}])`;
            case 'not in list':
                return `!window.compareArraysContainsAnyValues(target.${conditionObg.field}?.map(el => el?.id), [${JSON.stringify(conditionObg.value?.id)}])`;
            default:
                console.warn('uncorrect value of filter');
                return 'value is Object';
        }
    }
};
let generateConditionGroup = (rulesSet, conjunction, targetSample, level = null) => {
    let resalt = '';
    if (Array.isArray(rulesSet)) {
        rulesSet.forEach((el, index) => {
            if (level) {
                resalt = resalt + 'return ';
                level = false;
            }
            if (el?.type === 'conjunction') {
                resalt = resalt + generateConditionGroup(el.conditions, el.operator, targetSample, false);
            } else {
                resalt = resalt + generateCondition(el, targetSample);
            }
            if (index !== rulesSet.length - 1 && resalt !== 'return ') {
                resalt = resalt + transformConjunction(conjunction);
            }
        });
    }
    return resalt;
};

export function checkConditions(target, conditionsSet) {
    if (!conditionsSet?.conditions || conditionsSet.conditions?.length < 1) {
        return true;
    }
    let conditions = JSON.parse(JSON.stringify(conditionsSet));
    let conditionStr = generateConditionGroup(conditions.conditions, conditions.operator, target, true);
    //  console.log("conditionStr",conditionStr);
    let fun = new Function('target', conditionStr);
    return fun(target);
}

export function applyFilter(target, filterset) {
    // console.log(target, filterset);
    if (!filterset || filterset?.conditions?.length <= 0 || !target || target?.length <= 0) {
        return target;
    } else {
        // console.warn('applyFilter',filterset,target?.length);
        return target.filter(el => checkConditions(el, JSON.parse(JSON.stringify(filterset))));
        // console.log(res);
        // return target;
    }
}

export function getFilterFields(filterset) {
    let res = {};

    function find(filterSet) {
        if (!Array.isArray(filterSet)) {
            return;
        }
        filterSet.forEach(element => {
            if (element?.type === 'condition') {
                res[element.field] = element.value;
            } else if (element.type === 'conjunction') {
                find(element?.conditions);
            } else {
                console.log('not find');
            }
        });
    }

    if (filterset) {
        find(filterset);
    }
    return res;
}

export function getDatesByPeriod(period) {
    function timestamp(date) {
        return dayjs(date).valueOf();
    }

    const today = dayjs().startOf('day');
    const endOfToday = dayjs().endOf('day');

    function calculateDates(offset) {
        const resultDate = dayjs().add(offset, 'day');
        const startOfResultDate = resultDate.startOf('week');
        const endOfResultDate = resultDate.endOf('week');
        return { start: timestamp(startOfResultDate), end: timestamp(endOfResultDate) };
    }

    if (period === 'today') {
        return { start: timestamp(today), end: timestamp(endOfToday) };
    } else if (period === 'yesterday') {
        const yesterday = dayjs().subtract(1, 'day').startOf('day');
        const endOfYesterday = dayjs().subtract(1, 'day').endOf('day');
        return { start: timestamp(yesterday), end: timestamp(endOfYesterday) };
    } else if (period === 'tomorrow') {
        const tomorrow = dayjs().add(1, 'day').startOf('day');
        const endOfTomorrow = dayjs().add(1, 'day').endOf('day');
        return { start: timestamp(tomorrow), end: timestamp(endOfTomorrow) };
    } else if (period === 'this_week') {
        return calculateDates(0);
    } else if (period === 'last_week') {
        return calculateDates(-7);
    } else if (period === 'next_week') {
        return calculateDates(7);
    } else if (period === 'this_month') {
        const startOfMonth = dayjs().startOf('month');
        const endOfMonth = dayjs().endOf('month');
        return { start: timestamp(startOfMonth), end: timestamp(endOfMonth) };
    } else if (period === 'last_month') {
        const startOfLastMonth = dayjs().subtract(1, 'month').startOf('month');
        const endOfLastMonth = dayjs().subtract(1, 'month').endOf('month');
        return { start: timestamp(startOfLastMonth), end: timestamp(endOfLastMonth) };
    } else if (period === 'next_month') {
        const startOfNextMonth = dayjs().add(1, 'month').startOf('month');
        const endOfNextMonth = dayjs().add(1, 'month').endOf('month');
        return { start: timestamp(startOfNextMonth), end: timestamp(endOfNextMonth) };
    } else if (period === 'this_year') {
        const startOfYear = dayjs().startOf('year');
        const endOfYear = dayjs().endOf('year');
        return { start: timestamp(startOfYear), end: timestamp(endOfYear) };
    } else if (period === 'last_year') {
        const startOfLastYear = dayjs().subtract(1, 'year').startOf('year');
        const endOfLastYear = dayjs().subtract(1, 'year').endOf('year');
        return { start: timestamp(startOfLastYear), end: timestamp(endOfLastYear) };
    } else if (period === 'next_year') {
        const startOfNextYear = dayjs().add(1, 'year').startOf('year');
        const endOfNextYear = dayjs().add(1, 'year').endOf('year');
        return { start: timestamp(startOfNextYear), end: timestamp(endOfNextYear) };
    } else if (period === 'this_quarter') {
        return getQuarterDates(dayjs());
    } else if (period === 'last_quarter') {
        const lastQuarter = dayjs().subtract(1, 'quarter');
        return getQuarterDates(lastQuarter);
    } else if (period === 'next_quarter') {
        const nextQuarter = dayjs().add(1, 'quarter');
        return getQuarterDates(nextQuarter);
    } else {
        return null;
    }
}

function getQuarterDates(date) {
    const startOfQuarter = date.startOf('quarter');
    const endOfQuarter = date.endOf('quarter');
    return { start: dayjs(startOfQuarter).valueOf(), end: dayjs(endOfQuarter).valueOf() };
}

export function prepareGroupRequest(groupField, params) {
    let targetGroupField = groupField;
    let fields = {
        [targetGroupField]: {}
    };
    let order = [...(params.order ?? [])];

    return {
        ...params,
        fields,
        order,
        group_by: [targetGroupField]
        // with_groups: true
    };
}

export function addValuesToFilter(filter, values) {
    let curentFilter = JSON.parse(JSON.stringify(filter.conditions));
    function addValues(filterSet, values) {
        if (!Array.isArray(filterSet)) {
            return;
        }
        filterSet.forEach(element => {
            if (element?.type === 'condition' && element?.target === 'form') {
                if (values[element.value]) {
                    element.value = values[element.value];
                } else {
                    (element.comparator = 'empty'), (element.value = null);
                }
                delete element.target;
            } else if (element.field === 'id' && ['in list', 'not in list'].includes(element.comparator) && element.value?.length > 0 && typeof element.value[0] === 'object') {
                element.value = element.value.map(el => el.id);
            } else if (element.type === 'conjunction') {
                addValues(element?.conditions, values);
            } else {
                console.log('not find');
            }
        });
    }
    addValues(curentFilter, values);
    return curentFilter[0];
}
