<template>
    <div
        class="sp-input relative group/input"
        :class="{
            'sp-input_has-value': internalValue,
            'has-icon': iconName,
            focused: focused,
            'in-line': inLine,
            'space-between': spaceBetween,
            'has-label': labelName,
            underlined: variantUnderline
        }">
        <label
            class="sp-input__label relative flex w-full h-full"
            :style="{
                zIndex: focused ? '1' : ''
            }">
            <div v-if="variantUnderline" :class="[{ 'sp-input__label-underline': variantUnderline }]" :style="labelStyle">
                {{ labelName }}<span v-if="required" class="text-[#FF7C73]">*</span>
            </div>
            <div :class="[{ invisible: variantUnderline }, { 'sp-input__label-text': variantText }]" :style="labelStyle">
                {{ labelName }}<span v-if="required" class="text-[#FF7C73]">*</span>
            </div>
            <div
                class="sp-input__wrapper relative w-full h-full text-[14px] flex items-center gap-1 transition-[border-color] ease-out text-[#131416]"
                :class="[
                    {
                        'px-4 border bg-[#FFFFFF]': variantBase
                    },
                    {
                        'px-2 border bg-[#FFFFFF]': variantText
                    },
                    {
                        'border-y-2 border-t-transparent bg-transparent': variantUnderline
                    },
                    {
                        'hover:bg-[#F9F9F9] border-transparent': variantText && !focused && valid
                    },
                    { 'border-[#FF7C73]': !valid || isInvalidInteger },

                    {
                        'border-[#519df5]': focused && valid
                    },
                    {
                        'hover:border-[#979BB2]': !focused && valid && variantBase
                    },
                    {
                        'hover:border-b-[#979BB2]': !focused && valid && variantUnderline
                    },
                    {
                        'border-[#F9F9F9]': !focused && valid && variantUnderline
                    },
                    {
                        'border-[#C0C2CD]': !focused && valid && !variantUnderline
                    },

                    {
                        'cursor-not-allowed': disabled
                    }
                ]"
                :style="getStyleInput">
                <div v-if="$slots.prefix" class="select-none font-medium"><slot name="prefix"></slot></div>
                <div class="relative flex items-center w-full h-full">
                    <input
                        v-if="useTrim"
                        ref="baseInput"
                        class="w-full h-full relative disabled:text-[var(--sp-text-third)] disabled:cursor-not-allowed placeholder:text-[var(--sp-text-third)] bg-transparent border-none"
                        :style="{
                            fontSize: labelFontSize
                        }"
                        v-bind="customProps"
                        v-model.trim="internalValue"
                        @keydown.enter="$emit('enter')"
                        @focus="
                            () => {
                                $emit('focus');
                                focused = true;
                            }
                        "
                        @blur="handleBlur"
                        @keypress="checkInputNumber"
                        @input="inputHandler" />
                    <input
                        v-else
                        ref="baseInput"
                        class="w-full h-full relative disabled:text-[var(--sp-text-third)] disabled:cursor-not-allowed placeholder:text-[var(--sp-text-third)] bg-transparent border-none"
                        v-bind="customProps"
                        v-model="internalValue"
                        @keydown.enter="$emit('enter')"
                        @focus="
                            () => {
                                $emit('focus');
                                focused = true;
                            }
                        "
                        @blur="handleBlur"
                        @keypress="checkInputNumber"
                        @input="inputHandler" />
                    <div
                        class="sp-input__status-icons flex items-center"
                        :class="(showResetButton && internalValue?.length) || type === 'password' ? 'right-3' : 'right-1'"
                        @click="clickIcon">
                        <base-icon v-if="showTypingSvg && showEditingIcon" class="h-4 fill-[var(--sp-text-panel)]" name="buttonPencil" />
                        <base-loader v-if="showLoadingSpinner" size="xsmall" class="opacity-50" />
                        <base-icon
                            v-if="type === 'password'"
                            class="fill-[var(--sp-primary-hover)]"
                            :name="[typeInput === 'password' ? 'eyeVisible' : 'eyeNotVisible']"
                            viewBox="0 0 24 24"
                            style="height: 20px" />
                    </div>
                    <span
                        v-if="(placeholder && !labelName && (!placeholderHideNotEmpty || !internalValue?.length)) || (placeholder && !internalValue?.length)"
                        class="sp-input__placeholder text-sm left-0"
                        :class="[
                            { active: internalValue || focused }
                            // , underline ? 'left-0' : 'left-4'
                        ]"
                        :style="{
                            fontSize: placeholderFontSize,
                            fontWeight: placeholderFontWeight,
                            color: placeholderFontColor
                        }">
                        <slot name="placeholderIcon"></slot>
                        {{ placeholder || $t('inputs.search') }}
                    </span>
                    <BaseIcon
                        v-if="iconName"
                        class="sp-input__icon absolute top-[50%] translate-y-[-50%] h-[24px] w-[24px] fill-[#C0C2CD]"
                        :class="[variantUnderline ? 'left-0' : 'left-3']"
                        :name="iconName"
                        :style="positionIcon" />

                    <BaseButton v-if="showResetButton && internalValue?.length" variant="text" color="third" @click="resetInput" size="xs">
                        <template #icon>
                            <BaseIcon name="Clouse" class="h-[24px]"></BaseIcon>
                        </template>
                    </BaseButton>
                </div>
            </div>
            <div
                v-if="variantText && focused"
                class="sp-input__confirm-buttons absolute bottom-0 right-0 h-[50px] translate-y-[75%] p-1 flex gap-2 bg-[#FFFFFF] border border-[#EAEBEE] rounded-md shadow-[0px_10px_10px_2px_#686C6F0F] flex items-end">
                <BaseButton color="third" size="30" :rounded="false" class="sp-input__undo">
                    <template #icon>
                        <BaseIcon name="Clouse" class="h-[24px]"></BaseIcon>
                    </template>
                </BaseButton>
                <BaseButton size="30" :rounded="false">
                    <template #icon>
                        <BaseIcon name="tick-02" class="h-[24px]"></BaseIcon>
                    </template>
                </BaseButton>
            </div>
        </label>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, onMounted, PropType, nextTick } from 'vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import BaseButton from '@/components/ui/baseButton/BaseButton.vue';
import BaseLoader from '@/components/ui/BaseLoader.vue';
import { debounce } from '@/helpers';
import { BaseComponentSizeModel } from '@/models/base-component-size.model';
import { calculateBorderRadius, calculateMinFieldHeight } from '@/components/ui/utils/styles.utils';

type InputType = 'text' | 'password' | 'number' | 'email';
type InputVariant = 'base' | 'underline' | 'text';

export default defineComponent({
    name: 'BaseInputStyled',
    components: {
        BaseIcon,
        BaseLoader,
        BaseButton
    },
    props: {
        placeholderFontSize: { type: String },
        placeholderFontWeight: { type: Number },
        placeholderFontColor: { type: String },
        noOnlyBorder: { type: Boolean, default: false },
        value: { type: [String, Number] as PropType<string | number | null>, default: '' },
        type: { type: String as PropType<InputType>, default: 'text' },
        name: { type: String },
        labelName: { type: String },
        labelFontSize: { type: String, default: '14px' },
        labelFontColor: { type: String, default: '#131416' },
        labelFontWeight: { type: String, default: '400' },
        variant: {
            type: String as PropType<InputVariant>,
            default: 'base',
            validator: (value: string): boolean => ['base', 'underline', 'text'].includes(value)
        },
        placeholder: { type: String },
        placeholderHideNotEmpty: { type: Boolean, default: false },
        autofocus: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        inputWidth: { type: String, default: '' },
        debounceTime: { type: Number, default: 600 },
        autocomplete: { type: String, default: 'off' },
        showResetButton: { type: Boolean, default: false },
        inLine: { type: Boolean, default: false },
        spaceBetween: { type: Boolean, default: false },
        iconName: { type: String, default: '' },
        showLoadingSpinner: { type: Boolean, default: false },
        showTypingSvg: { type: Boolean, default: false },
        hideCalendarIcon: { type: Boolean, default: false },
        maxlength: { type: Number },
        valid: { type: Boolean, default: true },
        required: { type: Boolean, default: false },
        size: String as PropType<BaseComponentSizeModel | string>,
        useTrim: { type: Boolean, default: true },
        minNumber: { type: [Number, null] as PropType<number | null>, default: 0 },
        checkValidInteger: { type: Function as PropType<(value: string) => void>, default: () => {} },
        isInvalidInteger: { type: Boolean, default: false },
        isFocusNeed: { type: Boolean, default: false },
        preventScroll: { type: Boolean, default: false }
    },
    emits: ['update:value', 'input', 'focus', 'blur', 'debounceStarted', 'debounceFinished', 'enter'],
    setup(props, { emit }) {
        const focused = ref(false);
        const showEditingIcon = ref(false);
        const internalValue = ref(props.value);

        const typeInput = ref(props.type);
        const variantUnderline = computed(() => props.variant === 'underline');
        const variantText = computed(() => props.variant === 'text');
        const variantBase = computed(() => props.variant === 'base');

        const handleInput = debounce(() => {
            if (props.isInvalidInteger) return;
            if (props.type === 'number') {
                internalValue.value = Number(internalValue.value);
            }
            emit('update:value', internalValue.value);
            emit('input', internalValue.value);
            showEditingIcon.value = false;
            emit('debounceFinished');
        }, props.debounceTime);

        const customProps = computed(() => ({
            class: ['sp-input__input', resetButton.value, { hideCalendarIcon: props.hideCalendarIcon }, { 'bg-inherit': !props.valid }],
            type: typeInput.value,
            name: props.name,
            min: props.minNumber,
            autocomplete: props.autocomplete,
            disabled: props.disabled,
            maxlength: props.maxlength
        }));

        const positionIcon = computed(() => {
            if (props.inputWidth) return `right:${props.inputWidth}`;
            return 'right:100%';
        });

        const resetButton = computed(() => (props.showResetButton ? 'reset-btn' : ''));

        const getStyleInput = computed(() => {
            const styles: Record<string, string> = {
                width: props.inputWidth,
                minHeight: calculateMinFieldHeight(props.size),
                borderRadius: variantUnderline.value ? '' : calculateBorderRadius(props.size || 'md'),
                boxSizing: 'border-box',
                zIndex: focused.value ? '1' : ''
            };

            if (props.noOnlyBorder) styles.border = 'none';
            return styles;
        });

        const baseInput = ref<HTMLInputElement | null>(null);
        const focusOnInput = (): void => {
            baseInput.value?.focus({ preventScroll: props.preventScroll });
        };

        const focus = (): void => focusOnInput();

        const selectInput = (): void => {
            baseInput.value?.select();
        };

        const resetInput = (): void => {
            internalValue.value = props.type === 'number' ? null : '';
            emit('update:value', internalValue.value);
            focusOnInput();
        };
        const setInputValue = (value: any): void => {
            internalValue.value = value;
            emit('update:value', internalValue.value);
            emit('input', internalValue.value);
        };

        const handleBlur = async (e: FocusEvent): Promise<void> => {
            if (variantText.value) {
                handleInput();
            }
            if (props.isFocusNeed) {
                e.preventDefault();
                await nextTick();
                focus();
                return;
            }

            if (props.type === 'number' && internalValue.value === '') {
                internalValue.value = null;
            }
            const target = e.relatedTarget as HTMLElement | null;
            if (target?.classList?.contains('sp-input__undo')) {
                internalValue.value = props.value;
            } else {
                emit('blur', internalValue.value);
            }
            focused.value = false;
        };

        const checkInputNumber = (event: KeyboardEvent): void => {
            if (props.type !== 'number') return;

            let value = (event.target as HTMLInputElement).value;
            const isNumber = /^[-\d.]$/.test(event.key);
            const canEnterMinus = !(props.minNumber! >= 0 && event.key === '-');
            const isInvalidDotInput = (event.key === '.' && value === '') || (event.key === '.' && value.includes('.') && value[value.length - 1] === '.');

            if (!isNumber || isInvalidDotInput || !canEnterMinus) {
                event.preventDefault();
            }
        };

        const clickIcon = (): void => {
            if (props.type === 'password') {
                typeInput.value = typeInput.value === 'password' ? 'text' : 'password';
            }
        };

        const inputHandler = (event: Event): void => {
            props.checkValidInteger((event.target as HTMLInputElement).value);
        };

        watch(
            () => props.value,
            newValue => {
                if (focused.value) return;
                if (internalValue.value !== newValue) {
                    internalValue.value = newValue;
                }
            }
        );
        const labelStyle = computed(() => {
            return {
                color: props.labelFontColor,
                fontSize: props.labelFontSize,
                fontWeight: props.labelFontWeight
            };
        });

        watch(
            () => props.type,
            newValue => {
                typeInput.value = newValue;
            }
        );

        watch(internalValue, (newValue, oldValue) => {
            if (newValue !== oldValue && newValue !== props.value && !variantText.value) {
                showEditingIcon.value = true;
                emit('debounceStarted', newValue);
                handleInput();
            }
        });

        onMounted(() => {
            if (props.autofocus) focusOnInput();
        });

        return {
            focused,
            showEditingIcon,
            internalValue,
            typeInput,
            customProps,
            variantUnderline,
            variantText,
            variantBase,
            positionIcon,
            resetButton,
            getStyleInput,
            baseInput,
            focusOnInput,
            focus,
            selectInput,
            resetInput,
            setInputValue,
            handleBlur,
            checkInputNumber,
            clickIcon,
            inputHandler,
            labelStyle
        };
    }
});
</script>

<style lang="scss" scoped>
.in-line {
    &.space-between .sp-input__label {
        justify-content: space-between;
    }
}
.sp-input__label-text {
    padding-left: 8px;
}
.sp-input__label-underline {
    transition: transform 100ms;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(70%);
    z-index: 1;
}
.focused .sp-input__label-underline,
.sp-input_has-value .sp-input__label-underline {
    transform: translateY(0);
    left: 0;
    z-index: 1;
}

.has-icon {
    .sp-input__input {
        padding-left: 40px;
    }
}
.has-icon.underlined {
    .sp-input__input {
        padding-left: 28px;
    }
}

.has-icon {
    .sp-input__placeholder {
        left: 40px;
    }
}
.has-icon.underlined {
    .sp-input__placeholder {
        left: 28px;
    }
}

.sp-input__placeholder {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #979bb2;
    transition:
        transform 250ms ease-out,
        top 250ms ease-out,
        font-size 250ms ease-out;
    pointer-events: none;
    // z-index: 2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
}

.sp-input_has-value .sp-input__placeholder {
    top: 0;
    transform: translateY(-55%);
    font-size: 0.7rem;
    line-height: 1rem;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0) 60%);
    border-radius: 4px;
    // padding: 2px;
    color: var(--border-column-hover);
}

.sp-input_has-value .sp-input__placeholder {
    color: #131416;
}

.sp-input__status-icons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

label {
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: 0.15s opacity cubic-bezier(0.4, 0, 0.2, 1);
}

input {
    outline: none;
}

input.borderBottom {
    border: none;
    border-bottom: 2px solid #d7d7d7;
    background-color: inherit;
    border-radius: 0;
}

input.hideCalendarIcon[type='date']::-webkit-inner-spin-button,
input.hideCalendarIcon[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

@-moz-document url-prefix() {
    input.hideCalendarIcon[type='date'] {
        letter-spacing: -0.5px;
        width: 110px;
        clip-path: inset(0 1.8em 0 0);
    }
}
</style>
