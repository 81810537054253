<template>
    <main-template></main-template>

    <!-- <component  :is="$route.meta.template || 'MainTemplate'"></component> -->

    <div class="dialog-container" id="dialog-container"></div>
    <div class="modal-conteiner">
        <div style="position: relative; z-index: 0"></div>
        <div id="modal-container"></div>
    </div>
    <div class="alert-container absolute inset-0 flex px-4 py-6 pointer-events-none p-6 items-start justify-end">
        <div class="flex flex-col relative">
            <AlertComponent v-slot="{ alerts }">
                <AlertItem v-for="alert in alerts" :key="alert.id" :item="alert"></AlertItem>
            </AlertComponent>
        </div>
    </div>
    <GlobalModalForm />
    <CursorLoader />
    <BaseAsyncDialog />
    <BaseOpenComponent />
    <GlobalWSListners />
    <!-- add platform alert -->
</template>

<script>
import MainTemplate from '@/template/main/MainTemplate.vue';
import GlobalModalForm from '@/globalComponents/components/GlobalModalForm.vue';
import BaseAsyncDialog from '@/components/ui/BaseAsyncDialog.vue';
// import LandingTemplate from "@/template/LandingTemplate.vue";
import AlertItem from '@/components/platformAlert/AlertItem.vue';
import { useUserStore } from '@/stores/userStore';
import { mapActions, mapState } from 'pinia';
import BaseOpenComponent from '@/components/ui/BaseOpenComponent.vue';
import CursorLoader from '@/globalComponents/components/CursorLoader.vue';
import GlobalWSListners from '@/globalComponents/components/GlobalWSListners.vue';
import UAParser from 'ua-parser-js';
import { defineAsyncComponent, markRaw } from 'vue';

export default {
    name: 'App',
    components: {
        CursorLoader,
        BaseOpenComponent,
        MainTemplate,
        // LandingTemplate,
        AlertItem,
        GlobalModalForm,
        BaseAsyncDialog,
        GlobalWSListners
    },
    data() {
        return {
            windowWidth: null,
            windowHeight: null
        };
    },

    created() {
        // console.log("app createad");
        // this.getCurrentUserInfo();
    },
    methods: {
        ...mapActions(useUserStore, ['getCurrentUserInfo', 'setisMobile', 'setDeviceInfo']),
        checkUA() {
            this.$nextTick(() => {
                let result = false;
                let uaStr = navigator.userAgent;

                const parser = new UAParser(uaStr);
                let uspars = parser.getResult();
                this.setDeviceInfo(uspars);

                const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
                let isMob = toMatch.some(toMatchItem => {
                    return uaStr.match(toMatchItem);
                });

                if (isMob) {
                    result = isMob;
                }

                if (uspars.os.name && ['Android', 'iOS'].includes(uspars.os.name)) {
                    result = true;
                }
                if (uspars.device.type && ['mobile', 'tablet'].includes(uspars.device.type)) {
                    result = true;
                }

                this.windowWidth = window.innerWidth;
                this.windowHeight = window.innerHeight;

                let orientation = window.orientation; //orientation
                let isMobileBasedOnSize = this.windowWidth / this.windowHeight < 1; //proportion of mobile screen
                let smallScreen = orientation ? window.innerWidth < 768 || window.innerHeight < 768 : window.innerWidth < 768; //minimal desktop 768px
                let touchSupport = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0 || window.matchMedia('(pointer: coarse)').matches; //support touch

                //console.log('checking....', uspars,isMob,touchSupport, smallScreen, isMobileBasedOnSize);

                if ((touchSupport && isMobileBasedOnSize) || smallScreen) {
                    result = true;
                }

                this.$isMobile.value = result;
                this.setisMobile(result);
                // console.log(
                //     'isMobileBasedOnSize',
                //     isMobileBasedOnSize,
                //     '/smallScreen',
                //     smallScreen,
                //     '/support touch',
                //     touchSupport,
                //     '/$isMobile = ',
                //     this.$isMobile.value,
                //     '/ store isMobile = ',
                //     this.isMobile
                // );
            });
        },
        handleKeyDown(event) {
            if (event.ctrlKey && event.key === 'm') {
                this.$modal.openComponent({
                    component: markRaw(defineAsyncComponent(() => import('@/components/ui/BaseAIChat.vue'))),
                    props: {},
                    events: {}
                });
            }
        }
    },
    watch: {},
    computed: {
        ...mapState(useUserStore, ['isMobile'])
    },
    mounted() {
        this.checkUA();

        window.addEventListener('resize', this.checkUA);
        window.addEventListener('keydown', this.handleKeyDown);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkUA);
        window.removeEventListener('keydown', this.handleKeyDown);
    }
};
</script>
<style lang="scss">
.modal-conteiner {
    position: fixed;
    inset: 0px;
    pointer-events: none;
    overflow: hidden;
    z-index: 100;
}
</style>
