<template>
    <span></span>
</template>
<script setup>
import { onMounted, getCurrentInstance } from 'vue';
import { useEntitysStore } from '@/stores/entityStore';
const entitysStore = useEntitysStore();
const instance = getCurrentInstance();

const entityFormListner = payload => {
    switch (payload?.event) {
        case 'update':
            entitysStore.removeEntityForms(payload?.entity_id, payload?.id);
            break;
        default:
            break;
    }
};

onMounted(() => {
    if (instance) {
        const socket = instance.appContext.config.globalProperties.$soketio;
        socket?.on?.('FormView', entityFormListner);
    }
});
</script>
