<template>
    <div>
        <div v-if="isVisible" :style="loaderStyle" class="cursor-loader"></div>
    </div>
</template>

<script>
import { mapGetters } from 'pinia';
import { useCursorLoaderStore } from '@/stores/cursorLoader';

export default {
    name: 'CursorLoader',
    data() {
        return {
            isVisible: false,
            mouseX: 0,
            mouseY: 0
        };
    },
    computed: {
        ...mapGetters(useCursorLoaderStore, ['isCursorLoader']),
        loaderStyle() {
            return {
                top: `${this.mouseY - 15}px`,
                left: `${this.mouseX + 5}px`
            };
        }
    },
    watch: {
        isCursorLoader() {
            this.toggleLoader(this.isCursorLoader);
        }
    },
    methods: {
        toggleLoader(status) {
            this.isVisible = status;
        },
        updateMousePosition(event) {
            this.mouseX = event.clientX;
            this.mouseY = event.clientY;
        }
    },
    mounted() {
        window.addEventListener('mousemove', this.updateMousePosition);
    },
    beforeUnmount() {
        window.removeEventListener('mousemove', this.updateMousePosition);
    }
};
</script>

<style scoped lang="scss">
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.cursor-loader {
    pointer-events: none;
    position: fixed;
    width: 20px;
    height: 20px;
    border: 3px solid #ccc;
    border-top: 3px solid #083ff3;
    border-radius: 50%;
    animation: spin 0.5s linear infinite;
    z-index: 10000;
}
</style>
