<template>
    <div class="relative">
        <BaseButton color="third" variant="text" size="sm" @click="openNotificationBlock($event)" v-tooltip:bottom="$t('tooltip.notification')">
            <template #icon>
                <BaseIcon name="notification-01" class="h-[24px] w-[24px]"></BaseIcon>
            </template>
        </BaseButton>
        <RoundBadge v-if="unreadNotificationsCount" class="absolute top-0 translate-y-[-20%] right-0 translate-x-[20%] pointer-events-none" color="danger" size="sm">
            {{ unreadNotificationsCount }}
        </RoundBadge>
    </div>
    <base-modal
        v-if="showNotificationBlock"
        name="contex-menu"
        v-model="showNotificationBlock"
        :input-event="notificationBlockEvent"
        zindex="100"
        :content-width="width + 'px'"
        content-height="85dvh"
        content-type="context-menu"
        :position="isMobile ? 'center' : 'left'">
        <div :class="`notification__list w-[${width} px] h-[85dvh]`">
            <div class="list__header border-b-[1px] border-borderColor flex items-center" :class="[isMobile ? 'px-1' : 'px-4']">
                <div class="header__block flex items-center w-full">
                    <div class="flex" :class="[isMobile ? 'gap-[5px]' : 'gap-[15px]']">
                        <button
                            v-for="notifications in notificationsButtons"
                            :key="notifications.id"
                            @click="changeNotifications(notifications)"
                            class="header__btn flex gap-[7.5px] view text-sm px-[2px] pb-2 pt-4 self-end hover:text-primary"
                            :class="{ active: notifications.id === currentNotificationType }">
                            {{ notifications?.label }} <span class="text-gray5">({{ notifications?.length }})</span>
                        </button>
                    </div>

                    <div class="header__menu-btn ml-auto"></div>
                    <BaseButton v-if="currentNotification?.length" color="third" variant="text" size="sm" @click="openSubmenu($event)">
                        <template #icon>
                            <BaseIcon name="more-horizontal" class="h-[24px] w-[24px]"></BaseIcon>
                        </template>
                    </BaseButton>
                    <BaseButton color="third" variant="text" size="sm" @click="closeNotificationBlock">
                        <template #icon>
                            <BaseIcon name="Clouse" class="h-[24px] w-[24px]"></BaseIcon>
                        </template>
                    </BaseButton>
                </div>
            </div>
            <div class="flex justify-between px-4 py-2 gap-2 items-center w-full border-b border-b-[#EAEBEE] shadow-md bg-gray-50">
                <div class="flex-grow">
                    <BaseInputStyled
                        class="w-full"
                        :placeholder="$t('notifications.searchPlaceholder')"
                        :placeholderHideNotEmpty="true"
                        :value="searchFieldQuery"
                        @input="value => (searchFieldQuery = value.toLowerCase())"
                        :debounceTime="100"
                        placeholder-font-size="14px"
                        placeholder-font-color="rgb(19, 20, 22)"
                        variant="underline"
                        iconName="search-01"
                        ref="searchInput"
                        :showResetButton="true" />
                </div>

                <div>
                    <BaseToggle :label="$t('labels.onlyShowUnread')" :value="showUnreadOnly" @change="updateShowUnreadOnly" :style="{ width: 'fit-content' }" />
                </div>
            </div>

            <div class="row-list" id="taskList" v-if="currentNotification?.length">
                <platform-list :data="sortedNotifications">
                    <template v-slot:item="{ item }">
                        <NotificationsItem
                            :key="item.id"
                            :item="item"
                            @delete-notification="deleteNotification"
                            @mark-as-read="readNotification"
                            @mark-as-unread="markAsUnreadNotification"
                            @open-notification-instance="openNotificationInstance"
                            @close-notification-block="closeNotificationBlock"></NotificationsItem>
                    </template>
                </platform-list>
            </div>

            <div v-if="!currentNotification?.length && notifications?.length" class="empty--list">
                {{ $t('notifications.youveReadAllYourNotifications') }}
            </div>
            <div v-if="!notifications?.length" class="empty--list text-center p-3">
                {{ $t('notifications.youHaveNoNotifications') }}
            </div>
        </div>
    </base-modal>
    <base-dialog v-model="showClearDialog" contentWidth="500px" :showOwerlay="true" @modal-close="closeClearDialog">
        <h3 class="text-base font-semibold">{{ $t('modal.clearAllNotification') }}?</h3>
        <span>{{ $t('modal.itWillNotBePossibleToCancelThisOperation') }}!</span>
        <div class="flex w-full justify-end mt-5">
            <BaseButton class="dialog__btn" variant="base" color="success" size="sm" @click="clearCurrentNotifications" :label="$t('buttons.clear')" />

            <BaseButton class="dialog__btn ml-2" variant="text" size="sm" @click="closeClearDialog" :label="$t('buttons.cancel')" />
        </div>
    </base-dialog>

    <ContextMenu v-if="showSubmenu" :event="submenuEvent" :items="submenuItems" @changedValue="onSubmenuBtnClick" @closeContextMenu="closeSubmenu"></ContextMenu>
</template>

<script>
import BaseModal from '@/components/ui/BaseModal.vue';
import BaseButton from '@/components/ui/baseButton/BaseButton.vue';
import BaseDialog from '@/components/ui/BaseDialog.vue';
import PlatformList from '@/components/ui/platformList/PlatformList.vue';
import NotificationsItem from '@/components/platformNotifications/NotificationsItem.vue';
import ContextMenu from '@/components/ui/ContextMenu/ContextMenu.vue';
import BaseIcon from '@/components/ui/BaseIcon';
import RoundBadge from '@/components/ui/RoundBadge.vue';

import { mapState, mapActions } from 'pinia';
import { useNotificationsStore } from '@/stores/notificationsStore';
import { useTasksStore } from '@/stores/tasksStore';
import { useUserStore } from '@/stores/userStore';
import { useEntitysStore } from '@/stores/entityStore';
import { addEvent } from '../../../analytics/AnalyticsService';
import EntityApi from '@/api/entity';
import BaseToggle from '@/components/ui/BaseToggle.vue';
import BaseInputStyled from '@/components/ui/BaseInputStyled.vue';
import { toBoolean } from '@/helpers';

export default {
    name: 'HeaderNotifications',
    components: {
        BaseInputStyled,
        BaseToggle,
        PlatformList,
        NotificationsItem,
        BaseButton,
        BaseIcon,
        BaseDialog,
        ContextMenu,
        BaseModal,
        RoundBadge
    },
    props: {},

    data() {
        return {
            showNotificationBlock: false,
            notificationBlockEvent: null,
            showClearDialog: false,
            showInstanceModal: false,
            entityId: null,
            instanceId: null,
            currentNotificationType: 'all',
            searchFieldQuery: '',
            showSubmenu: false,
            submenuEvent: null,
            width: 530,
            showUnreadOnly: toBoolean(localStorage.getItem('notifications.showUnreadOnly') ?? '')
        };
    },
    // watch: {
    //     showUnreadOnly(newVal) {
    //         if (!newVal) {
    //             this.getNotifications();
    //         }
    //     }
    // },
    computed: {
        ...mapState(useNotificationsStore, ['notifications', 'initialNotifications']),
        ...mapState(useUserStore, ['isMobile']),
        unreadNotificationsCount() {
            if (this.notifications?.length) {
                let notRead = this.notifications.filter(el => !el.read);
                if (notRead?.length > 0) {
                    return notRead?.length;
                } else if (notRead?.length > 100) {
                    return '99+';
                }
                return null;
            } else return null;
        },
        mentionedNotifications() {
            return this.getNotificationsByEvent('mention');
        },

        assignedNotifications() {
            const messageIgnoreList = ['Followers'];
            return this.getNotificationsByEvent('you_added', el => {
                return !messageIgnoreList.includes(el.activity_data.message);
            });
        },

        allNotificationsLength() {
            let list = Array.isArray(this.notifications) ? this.notifications : [];
            if (this.showUnreadOnly) {
                list = list.filter(el => !el.read);
            }
            return list.length ?? 0;
        },
        currentNotification() {
            let list = [];

            if (this.notifications?.length) {
                if (this.currentNotificationType === 'all') {
                    list.push(...this.notifications);
                }
                if (this.currentNotificationType === 'mentioned') {
                    list.push(...this.mentionedNotifications);
                }
                if (this.currentNotificationType === 'assigned') {
                    list.push(...this.assignedNotifications);
                }
            }

            if (this.searchFieldQuery) {
                list = list.filter(el => {
                    const isIdEqual = el.instance_id?.toLowerCase() === this.searchFieldQuery;
                    const isInstanceNameEqual = el.activity_data?.instance_name?.toLowerCase()?.includes(this.searchFieldQuery);
                    return isIdEqual || isInstanceNameEqual;
                });
            }

            if (this.showUnreadOnly) {
                list = list.filter(el => !el.read);
            }
            return list;
        },
        sortedNotifications() {
            function compare(a, b) {
                let atrA = a.created_time;
                let atrB = b.created_time;
                // let atrA = a.datetime;
                // let atrB = b.datetime;
                if (atrA > atrB) return -1;
                if (atrA == atrB) return 0;
                if (atrA < atrB) return 1;
            }

            if (this.currentNotification && this.currentNotification.length > 0) {
                let notification = [...this.currentNotification];
                return notification.sort(compare);
            }
            return [];
        },
        submenuItems() {
            const items = [
                {
                    id: 'deleteAllNotifications',
                    label: this.$t('buttons.deleteAllNotifications'),
                    type: 'button',
                    icon: 'delete-02'
                }
            ];

            if (this.unreadNotificationsCount) {
                items.unshift({
                    id: 'markAllAsRead',
                    label: this.$t('buttons.markAllAsRead'),
                    type: 'button',
                    icon: 'tick-02'
                });
            }
            return items;
        },
        notificationsButtons() {
            return [
                {
                    id: 'all',
                    label: this.$t('notifications.tabs.all'),
                    length: this.allNotificationsLength
                },
                {
                    id: 'mentioned',
                    label: this.$t('notifications.tabs.mentioned'),
                    length: this.mentionedNotifications?.length
                },
                {
                    id: 'assigned',
                    label: this.$t('notifications.tabs.assigned'),
                    length: this.assignedNotifications?.length
                }
            ];
        }
    },
    methods: {
        ...mapActions(useNotificationsStore, [
            'getNewNotifications',
            'getNotifications',
            'addNotifications',
            'updateNotifications',
            'deleteNotifications',
            'markNotificationsAs',
            'insertNotificationsByWS',
            'updateNotificationsByWS',
            'deleteNotificationsByWS'
        ]),
        ...mapActions(useTasksStore, ['loadTasksByPin', 'updateTask']),
        ...mapActions(useUserStore, ['getEnvironmentByName']),
        ...mapActions(useEntitysStore, ['getEntityCard']),
        deleteNotification(id) {
            this.deleteNotifications([id]);
        },
        updateShowUnreadOnly(value) {
            this.showUnreadOnly = value;
            localStorage.setItem(`notifications.showUnreadOnly`, JSON.stringify(value));
        },
        changeNotifications(notifications) {
            this.currentNotificationType = notifications.id;
            let notificationLabel;

            switch (notifications.id) {
                case 'all':
                    notificationLabel = 'All';
                    break;
                case 'mentioned':
                    notificationLabel = 'Mentioned';
                    break;
                case 'assigned':
                    notificationLabel = 'Assigned';
                    break;
            }
            addEvent({
                category: 'Notification card',
                action: 'lmbc',
                label: `Clicked to show ${notificationLabel} notifications`,
                entity: 'notification',
                instance: ''
            });
        },
        readNotification(id) {
            this.markNotificationsAs('read', [id]);
        },
        markAsUnreadNotification(id) {
            this.markNotificationsAs('unread', [id]);
        },
        markAllAsRead() {
            void this.markNotificationsAs('read', this.getUnreadNotificationIds());
        },

        getNotificationsByEvent(event, callback) {
            let list = [];
            if (this.notifications?.length) {
                list = this.notifications.filter(el => {
                    const matchesEvent = el.activity_data.event === event;
                    const isUnread = this.showUnreadOnly ? !el.read : true;

                    if (matchesEvent && isUnread) {
                        if (typeof callback === 'function') {
                            return callback(el);
                        }
                        return true;
                    }
                    return false;
                });
            }
            return list;
        },

        openNotificationBlock(event) {
            this.updateWidth();
            this.notificationBlockEvent = event;
            this.showNotificationBlock = true;

            this.getNotifications();
            addEvent({
                category: 'Navigation Panel',
                action: 'lmbc',
                label: 'Open notifications card',
                entity: 'notification',
                instance: ''
            });
        },

        async openNotificationInstance(notifications) {
            const cardName = this.getEntityCard(notifications.entity_id);

            if (notifications.entity_id === 'BlogPost') {
                this.$router.push({
                    name: 'News',
                    query: { id: notifications.instance_id }
                });

                this.closeNotificationBlock();
                return;
            }

            if (notifications.entity_id === 'CRMActivities') {
                const instanceobj = await EntityApi.getInstance('CRMActivities', notifications.instance_id);

                if (!instanceobj) {
                    return;
                }

                if (instanceobj.activity_type === 'Task') {
                    const taskObj = await EntityApi.getInstance(instanceobj.sub_entity_id, instanceobj.sub_instance_id);
                    if (!taskObj) {
                        return;
                    }
                }

                const id = notifications.instance_id;

                const modalProps = this.isMobile
                    ? {
                          class: '',
                          borderRTL: false,
                          borderRTR: false,
                          borderRBL: false,
                          borderRBR: false
                      }
                    : {
                          horizontalAlign: 'right',
                          verticalAlign: 'center',
                          class: 'p-4',
                          fullHeight: true
                      };

                const activitiesModalSettings = {
                    Meeting: {
                        id,
                        name: 'CRMActivities',
                        entityId: 'CRMActivities',
                        cardName: 'MeetingCard',
                        system: false,
                        useCopyLink: true,
                        useInFavorite: false,
                        modalProps,
                        props: {
                            activityObj: instanceobj,
                            id,
                            activityType: 'Meeting'
                        },
                        stick: this.isMobile
                            ? { isFullSize: true }
                            : {
                                  minw: 980,
                                  resizable: false,
                                  width: 980,
                                  horizontalPosition: 'right',
                                  top: 56,
                                  isNotModalFullSize: true
                              },
                        events: {},
                        onClose: () => {}
                    },

                    Task: {
                        id: instanceobj.sub_instance_id,
                        name: 'TaskCard',
                        props: {
                            id: instanceobj.sub_instance_id
                        },
                        onClose: () => {}
                    },

                    Call: {
                        id,
                        name: 'CRMActivities',
                        entityId: 'CRMActivities',
                        cardName: 'CallCard',
                        system: false,
                        useCopyLink: true,
                        useInFavorite: false,
                        modalProps,
                        props: {
                            activityObj: instanceobj,
                            id,
                            activityType: 'Call'
                        },
                        stick: this.isMobile
                            ? { isFullSize: true }
                            : {
                                  minw: 980,
                                  resizable: false,
                                  width: 980,
                                  horizontalPosition: 'right',
                                  top: 56,
                                  isNotModalFullSize: true
                              },
                        events: {},
                        onClose: () => {}
                    },

                    'E-mail': {
                        id,
                        name: 'CRMActivities',
                        entityId: 'CRMActivities',
                        cardName: 'EmailCard',
                        system: false,
                        useCopyLink: true,
                        useInFavorite: false,
                        modalProps,
                        props: {
                            activityObj: instanceobj,
                            id,
                            activityType: 'E-mail'
                        },
                        stick: this.isMobile
                            ? { isFullSize: true }
                            : {
                                  minw: 620,
                                  resizable: false,
                                  width: 620,
                                  horizontalPosition: 'right',
                                  top: 56,
                                  isNotModalFullSize: true
                              },
                        events: {},
                        onClose: () => {}
                    }
                };

                this.$modal.open(activitiesModalSettings[instanceobj.activity_type]);

                this.closeNotificationBlock();
                return;
            }

            const param = {
                name: cardName,
                id: notifications.instance_id,
                entityId: notifications.entity_id,
                system: false,
                props: {
                    id: notifications.instance_id,
                    entityId: notifications.entity_id,
                    scrollToActivity: notifications?.activity_data?.activity_id
                },
                events: {}
            };

            addEvent({
                category: 'Notification card',
                action: 'lmbc',
                label: 'Click to open notification',
                entity: 'notification',
                instance: notifications.instance_id
            });

            this.$modal.open(param);
            // this.closeNotificationBlock();
        },
        closeNotificationBlock() {
            addEvent({
                category: 'Notification card',
                action: 'lmbc',
                label: 'Close Notification card',
                entity: 'notification',
                instance: ''
            });
            this.notificationBlockEvent = null;
            this.showNotificationBlock = false;
        },
        openClearDialog() {
            this.showClearDialog = true;
        },
        closeClearDialog() {
            addEvent({
                category: 'Notification card',
                action: 'lmbc',
                label: 'Close clear notifications dialogue',
                entity: 'notification',
                instance: ''
            });
            this.showClearDialog = false;
        },

        openSubmenu(e) {
            addEvent({
                category: 'Notification card',
                action: 'lmbc',
                label: 'Open submenu',
                entity: 'notification',
                instance: ''
            });
            this.submenuEvent = e;
            this.showSubmenu = true;
        },
        closeSubmenu() {
            this.showSubmenu = false;
            this.submenuEvent = null;
        },
        onSubmenuBtnClick(obgValue) {
            if (obgValue && obgValue.id) {
                addEvent({
                    category: 'Notification card',
                    action: 'lmbc',
                    label: 'Clicked submenu button ' + obgValue.id,
                    entity: 'notification',
                    instance: ''
                });
            }
            switch (obgValue?.id) {
                case 'markAllAsRead':
                    this.markAllAsRead();
                    break;
                // case 'markAllAsUnread':
                //     this.markAllAs();
                //     break;
                case 'deleteAllNotifications':
                    this.openClearDialog();
                    break;
                case 'deleteAllUnreadNotifications':
                    this.clearUnreadNotifications();
                    break;

                default:
                    break;
            }
        },

        async clearCurrentNotifications() {
            this.deleteNotifications(this.getCurrentNotificationIds());
        },
        async clearUnreadNotifications() {
            this.deleteNotifications(this.getUnreadNotificationIds());
        },
        getCurrentNotificationIds() {
            return this.currentNotification.map(notification => notification.id);
        },
        getUnreadNotificationIds() {
            return this.notifications.filter(el => !el.read).map(notification => notification.id);
        },
        updateWidth() {
            if (this.isMobile) {
                this.width = Math.floor(0.9 * window.innerWidth);
            }
        }
    },
    async created() {
        this.getNewNotifications();
    },
    mounted() {
        this.updateWidth();
        this.getNotifications();
    },
    sockets: {
        notice(payload) {
            if (payload.event) {
                switch (payload.event) {
                    case 'insert':
                        this.insertNotificationsByWS(payload.data);
                        break;
                    case 'update':
                        console.log('Update notice by WS', payload.data);
                        this.updateNotificationsByWS(payload.data);
                        break;
                    case 'delete':
                        this.deleteNotificationsByWS(payload.data);
                        break;
                    default:
                        break;
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.close-icon {
    width: 15px;
    margin-top: 7px;
    margin-right: 10px;
}

.list__header {
    .header__block {
        .header__btn {
            position: relative;

            &.active {
                color: var(--primary);

                &:before {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    content: '';
                    width: 100%;
                    height: 2px;
                    background-color: var(--primary);
                }
            }
        }
    }
}

.checkbox {
    margin-right: auto;
}

.button {
    margin-left: 8px;
}

.button.close {
    position: absolute;
    top: 4px;
    right: 4px;
}

.row-list {
    height: calc(100% - 102px);
    overflow-y: auto;
    position: relative;
}

.empty--list {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 25vh;
    max-height: 80vh;
}

.dialog__btn {
    & + & {
        margin-left: 0.5rem;
    }
}
</style>
