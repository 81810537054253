<template>
    <div class="header-menu h-full flex grow flex-nowrap select-none" :class="[isMobile ? 'flex-col' : '']" :data-id="wrapperId">
        <HeaderMenuElement
            v-for="(item, index) in visibleElements"
            :key="index"
            :item="item"
            :maxLabelWidth="props.maxLabelWidth"
            @showProperties="
                (ev, id, typ) => {
                    onShowProperties(ev, id, typ, item);
                }
            "
            @elementClick="onElementClick"
            :actionsMenuOpened="clickedElementId === item.id && clickedElementType === 'actions' && contextVisible === true"
            :childerenMenuOpened="clickedElementId === item.id && clickedElementType === 'children' && contextVisible === true"
            :active="isMenuElementActive(item, activeMenuElementId)" />
        <div
            v-if="maxVisibleElementsArrayLength < formatedItems.length"
            class="flex flex-nowrap items-center fade-in-animation"
            :class="[isMobile ? '' : 'h-full']"
            @click="onMoreClick">
            <HeaderDivider v-if="!isMobile" />
            <div
                class="flex relative h-full items-center py-1 group/menu-element cursor-pointer gap-1"
                :class="[
                    clickedElementId === 'more' && contextVisible === true
                        ? isMobile
                            ? 'text-[#131416] fill-[#C0C2CD]'
                            : 'text-[#FFFFFF] fill-[#FFFFFF]'
                        : isMobile
                          ? 'text-[#131416] fill-[#C0C2CD]'
                          : 'text-[#C0C2CD] fill-[#C0C2CD]',
                    isMobile ? 'flex-auto justify-between hover:bg-[#519df524] px-4' : 'px-2'
                ]">
                <div class="flex pointer-events-none" style="color: #c0c2cd">
                    <BaseIcon v-if="isMobile" name="dotsHorizontal" class="h-[24px] w-[24px] mr-2"></BaseIcon>

                    <span class="font-medium whitespace-nowrap truncate" :class="[isMobile ? 'text-[#131416]' : 'group-hover/menu-element:text-[#FFFFFF]']">
                        {{ $t('buttons.more') }}</span
                    >
                </div>

                <BaseButton variant="text" color="third" size="xs" class="pointer-events-none">
                    <template #icon>
                        <BaseIcon name="arrow-down-01" class="h-[24px] w-[24px]" :class="[isMobile ? '' : 'group-hover/menu-element:fill-[#FFFFFF]']"></BaseIcon>
                        <!-- 'fill-[#519DF5]' -->
                    </template>
                </BaseButton>
                <div v-if="isMoreActive" class="absolute w-full bottom-0 left-0 h-0 border-t-2 border-t-[#519DF5] pointer-events-none"></div>
            </div>
        </div>

        <ContextMenu :items="contextMenuItems" v-if="contextVisible && !isMobile" :event="coordinateEvent" @changedValue="handleChange" @close="handleClose" :maxHeight="1000" />
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed, nextTick, getCurrentInstance } from 'vue';
import HeaderMenuElement from './components/HeaderMenuElement.vue';
import HeaderDivider from '@/components/platformHeader/components/HeaderDivider.vue';
import { getTextWidth, getBodyFontFamily } from '@/helpers/common';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import ContextMenu from '@/components/ui/ContextMenu/ContextMenu.vue';
// import { useRouter } from "vue-router";

// const router = useRouter();
const instance = getCurrentInstance();
const modal = instance.appContext.config.globalProperties.$modal;
const router = instance.appContext.config.globalProperties.$router;

//const { proxy } = getCurrentInstance();
//const isMobile = proxy.$isMobile.value;
import { useUserStore } from '@/stores/userStore';
import { useEntitysStore } from '@/stores/entityStore';
const { getFormById } = useEntitysStore();

import BaseButton from '@/components/ui/baseButton/BaseButton.vue';
import { uuid } from 'vue-uuid';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
import { addEvent } from '../../../analytics/AnalyticsService';
const emit = defineEmits(['click-item']);

const props = defineProps({
    items: {
        type: Array,
        default: () => []
    },
    limitItems: {
        type: Number,
        default: 99
    },
    maxLabelWidth: {
        type: Number,
        default: 200
    }
});

const isMobile = computed(() => useUserStore().isMobile);

const windowWidth = ref();

const formatedItems = computed(() => {
    return Array.isArray(props.items) ? [...props.items.reduce((acc, el) => [...acc, ...el], []).filter(el => el.type !== 'title')] : [];
});

const activeMenuElementId = ref(null);

function updateMenuState(to) {
    activeMenuElementId.value = getActiveMenuItemId(formatedItems.value, to);
}

router.afterEach(to => {
    updateMenuState(to);
});

const wrapperId = uuid.v4();

const maxVisibleElementsArrayLength = ref(0);

const gapInsideElement = 4;
const paddingElement = 8;
const widthIcon = 24;
const paddingBadge = 6;
const elementFont = `600 14px ${getBodyFontFamily()}`;
const badgeFont = `700 10px ${getBodyFontFamily()}`;
const dividerWidth = 9;
const resizeBarrier = 2;

const widthMore = getTextWidth(t('buttons.more'), elementFont) + gapInsideElement + widthIcon + paddingElement * 2 + dividerWidth;

function findNameByParentId(arr, par_id) {
    for (const item of arr) {
        if (item.id == par_id) {
            return item;
        }
        if (item.children && item.children.length > 0) {
            const obj = findNameByParentId(item.children, par_id);
            if (obj) {
                return obj;
            }
        }
    }
    return null;
}

const getElementWidth = element => {
    return (
        paddingElement * 2 +
        Math.min(getTextWidth(element.name, elementFont), props.maxLabelWidth) +
        (element.pins ? getTextWidth(element.pins, badgeFont) + paddingBadge * 2 + gapInsideElement : 0) +
        (element.children?.length ? widthIcon + gapInsideElement : 0)
    );
};

const setVisibleElements = () => {
    if (!formatedItems.value?.length) {
        return;
    } else {
        if (isMobile.value) {
            maxVisibleElementsArrayLength.value = formatedItems.value.length;
            return;
        }
    }
    const elementsContainer = document.querySelector(`[data-id="${wrapperId}"].header-menu`);
    const elementsContainerWidth = elementsContainer?.offsetWidth;
    let tempMaxVisibleArrayLength = 0;
    let tempWidth = 0;
    while (
        tempMaxVisibleArrayLength < Math.min(formatedItems.value?.length, props.limitItems) &&
        tempWidth + getElementWidth(formatedItems.value?.[tempMaxVisibleArrayLength]) + resizeBarrier < elementsContainerWidth
    ) {
        tempWidth += getElementWidth(formatedItems.value?.[tempMaxVisibleArrayLength]) + resizeBarrier;

        tempMaxVisibleArrayLength += 1;
    }

    if (tempMaxVisibleArrayLength < formatedItems.value?.length) {
        while (tempWidth + widthMore > elementsContainerWidth && tempMaxVisibleArrayLength > 0) {
            tempWidth -= getElementWidth(formatedItems.value?.[tempMaxVisibleArrayLength - 1]) + resizeBarrier;
            tempMaxVisibleArrayLength -= 1;
        }
    }

    if (maxVisibleElementsArrayLength.value !== tempMaxVisibleArrayLength) {
        maxVisibleElementsArrayLength.value = tempMaxVisibleArrayLength;
    }
};

const handleResize = entries => {
    window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
            return;
        }
        for (const entry of entries) {
            if (entry.target.classList.contains('header-menu')) {
                setVisibleElements();
            }
        }
    });
};

const resizeObserver = new ResizeObserver(handleResize);

onMounted(() => {
    windowWidth.value = window.innerWidth;

    const headerMenu = document.querySelector(`[data-id="${wrapperId}"].header-menu`);
    if (headerMenu) {
        resizeObserver.observe(headerMenu);
    }
    updateMenuState(router.currentRoute.value);
});
onUnmounted(() => {
    resizeObserver.disconnect();
});

const visibleElements = computed(() => formatedItems.value.slice(0, maxVisibleElementsArrayLength.value));

const isMoreActive = computed(() => {
    return formatedItems?.value.slice(maxVisibleElementsArrayLength.value).some(item => isMenuElementActive(item, activeMenuElementId.value));
});

const contextVisible = ref(false);
const coordinateEvent = ref(null);
const contextMenuItems = ref([]);
let clickedElementId;
let clickedElementType;

const onElementClick = async (event, element) => {
    let par_folder = null;
    if (element.parent_id) {
        let flatArray = props.items.reduce((acc, val) => acc.concat(val), []);
        par_folder = findNameByParentId(flatArray, element.parent_id);
    }

    addEvent({
        category: 'Navigation Panel',
        action: 'lmbc',
        label:
            'Open ' +
            (element.alias ? element.alias : element.properties && element.properties.entity ? element.properties.entity : element.name) +
            ' page' +
            (par_folder ? ' from submenu' : ''),
        entity: element.alias,
        instance: element.id
    });
    if ((element.action && element.properties) || (!element.href && element.properties)) {
        let formParam = {};
        if (element.properties?.formId) {
            const elementForm = await getFormById(element.properties.formId);
            if (elementForm) {
                formParam.id = elementForm.id;
                formParam.cardTitle = elementForm.name;
                formParam.trayTitle = elementForm.name;
                if (elementForm.form_data.formSettings.formInitWidth || elementForm.form_data.formSettings.formInitFullScreen) {
                    formParam.stick = {
                        ...(elementForm.form_data.formSettings.formInitWidth ? { width: elementForm.form_data.formSettings.formInitWidth } : {}),
                        minw: elementForm.form_data.formSettings?.formInitMinWidth ?? 300,
                        minh: elementForm.form_data.formSettings?.formInitMinHeight ?? 300,
                        isFullSize: !!elementForm.form_data.formSettings?.formInitFullScreen,
                        formId: elementForm.id,
                        resizable: elementForm.form_data.formSettings?.formResizable ?? true
                    };
                }
            }
        }

        modal.open({ ...element.properties, ...formParam });
        return;
    }
    if (element.disabled) return;
    if (element.href) {
        let url = element.href;
        if (element?.alias?.length > 0 && element.href.indexOf(element.alias) == -1) {
            url = url + `#${element.alias}`;
        }

        emit('click-item', event, element);
        router.push(url);
    }
    // else if (router.href != `/base/${element.id}`) {
    //   router.push({ name: "base", params: { id: element.id } });
    // }
    modal.hideAll();
};

function generateContextMenuItem(item) {
    return typeof item === 'object' && item !== null
        ? {
              ...item,
              id: item.id,
              label: item.name,
              type: item.href ? 'url' : 'button',
              icon: item.icon,
              active: isMenuElementActive(item, activeMenuElementId.value),
              ...(item.href ? { url: item.href } : []),
              ...(item.children
                  ? {
                        items: item.children.map(generateContextMenuItem)
                    }
                  : [])
          }
        : {};
}

const onMoreClick = async event => {
    addEvent({
        category: 'Navigation Panel',
        action: 'lmbc',
        label: 'Click More button to show elements menu of navigation panel',
        entity: 'header_submenu',
        instance: ''
    });
    contextVisible.value = false;
    if (clickedElementId === 'more') {
        clickedElementId = null;
        return;
    }
    await nextTick();
    contextMenuItems.value = formatedItems?.value.slice(maxVisibleElementsArrayLength.value)?.map(generateContextMenuItem);
    coordinateEvent.value = event;
    contextVisible.value = true;
    clickedElementId = 'more';
};

const onShowProperties = async (event, elementId, type, item) => {
    let type_submenu = 'submenu';
    if (type == 'actions') {
        type_submenu = 'quick action';
    }

    addEvent({
        category: 'Navigation Panel ' + type_submenu,
        action: 'lmbc',
        label: 'Show ' + type_submenu + ' for ' + item.alias + ' from navigation panel',
        entity: elementId,
        instance: item.alias ? item.alias : item.name
    });

    contextVisible.value = false;
    if (clickedElementId === elementId && clickedElementType === type) {
        clickedElementId = null;
        return;
    }
    await nextTick();
    contextMenuItems.value = formatedItems.value.find(el => el.id === elementId)?.[type].map(generateContextMenuItem);
    coordinateEvent.value = event;
    contextVisible.value = true;
    clickedElementId = elementId;
    clickedElementType = type;
};

const handleChange = (value, buttonEvent, object) => {
    onElementClick(buttonEvent, object);
};

const handleClose = () => {
    clickedElementId = null;
    clickedElementType = null;
    contextVisible.value = false;
};

function getActiveMenuItemId(arrayOfElements, router) {
    const hrefArr = router?.href?.split('#');

    function checkIfActive(element) {
        if (hrefArr && hrefArr?.[0] === '/external') {
            return hrefArr?.[1]?.startsWith(element?.alias);
        } else if (hrefArr) {
            return element?.href === '/' ? hrefArr?.[0] === element?.href : hrefArr?.[0].startsWith(element?.href);
        }
        return false;
    }

    function getActiveElements(elements) {
        return elements.reduce((acc, el) => {
            const isActive = checkIfActive(el);
            const childrenActiveElements = el.children ? getActiveElements(el.children) : [];
            return [...acc, ...(isActive ? [el] : []), ...childrenActiveElements];
        }, []);
    }

    const activeElementsArray = getActiveElements(arrayOfElements);

    if (activeElementsArray.length === 0) {
        return null;
    }

    const longestHrefObject = activeElementsArray.reduce((longest, current) => {
        return current.href.length > longest.href.length ? current : longest;
    }, activeElementsArray[0]);
    return longestHrefObject.id;
}

function isMenuElementActive(item, activeId) {
    if (item.id === activeId) {
        return true;
    }
    return item.children?.some(child => isMenuElementActive(child, activeId)) || false;
}
</script>

<style lang="scss" scoped>
.fade-in-animation {
    opacity: 0;
    animation: fadeIn 0.4s forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
</style>
