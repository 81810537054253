<template>
    <div
        class="notification__item flex items-start min-h-[74px] cursor-pointer border-b-[2px] border-gray3"
        :id="`notification-item-${item.id}`"
        :class="[[{ unread: !areNotificationRead, 'bg-gray4': !item.read, 'text-gray5': item.read }], isMobile ? 'p-2' : 'p-4']"
        ref="currentEl"
        @click="openNotificationInstance(item, $event)">
        <div class="notification__user--avatar shrink-0">
            <BaseAvatar :user="item?.user_initiator" :widthPic="40" :heightPic="40" @userClicked="openUserInfo"></BaseAvatar>
        </div>

        <div class="notification__description grow" :class="[isMobile ? 'ml-2' : 'ml-5']">
            <div class="description__header flex items-center gap-4">
                <div class="flex gap-1">
                    <span class="header__user--name text-[14px] font-semibold" :class="item.read ? 'text-gray5' : 'text-[#30363c]'">{{ fullName }}</span>
                    <span class="header__action text-[14px]">{{ performedAction }}</span>
                </div>

                <div class="ml-auto pr-7 shrink-0 flex items-center gap-1.5" v-if="!isMobile">
                    <div v-if="!item.read" class="mark-new-dot h-1.5 w-1.5 bg-primary rounded"></div>
                    <span class="header__date text-[14px] text-[gray5]"> {{ notificationPublishTime }}</span>
                </div>

                <BaseButton
                    class="header__menu--btn ml-2"
                    :class="[isMobile ? 'flex' : 'hidden']"
                    size="xs"
                    color="third"
                    variant="text"
                    data-action="more"
                    @click="toggleSubmenu($event)">
                    <template #icon> <BaseIcon name="more-horizontal" class="h-[24px] w-[24px]"></BaseIcon> </template
                ></BaseButton>
            </div>

            <div class="description__main max-w-full">
                <div class="main__task--name inline-flex gap-1 text-[14px]" v-if="instanceName">
                    <template v-if="instanceLabel">{{ instanceLabel }}:</template>
                    <span class="text-gray5 break-all">{{ instanceName }}</span>
                </div>

                <div class="mention__message text-[13px]" v-if="item?.activity_data?.message && typeof item?.activity_data?.message !== 'object'" ref="notificationMessage">
                    <span class="message" :class="[{ 'h-[75px] block overflow-hidden': hideMessage }]" v-html="item?.activity_data?.message"></span>
                    <BaseButton v-if="isLargeMessage" size="xs" :label="showMessageBtnText" @click.stop="showMessage" color="secondary" varian="base" class="mt-2.5" />
                </div>

                <FieldList showChangeInline :fields="item?.activity_data?.fields" class="text-[13px] mt-2">
                    <template v-slot:field="{ field }">
                        <div class="description__value max-w-full">
                            <p v-if="field.field_name !== 'stage' && field.data_type !== 'DateTime'" class="description__title text-[13px]">
                                - {{ determinePerformedAction(field) }}
                                <span class="updated__field">{{ $t(`notifications.${field.field_name}`) }}:</span>
                            </p>
                            <div class="description__wrapper flex items-center flex-wrap gap-[5px]">
                                <div class="field old">
                                    <ActivityField v-if="field?.activity_type !== 'added'" :isNotifications="true" :field="field" :keyField="'old'"></ActivityField>
                                </div>
                                <base-icon v-if="field?.activity_type !== 'added'" class="arrow w-5 h-5 mx-1 mt-1" :name="'arrowRight02'" viewBox="0 0 24 24"></base-icon>
                                <div class="field new">
                                    <ActivityField :field="field" :isNotifications="true" :keyField="'new'"></ActivityField>
                                </div>
                            </div>
                        </div>
                    </template>
                </FieldList>
                <div v-if="item.activity_data?.comment_id" class="flex w-full justify-end" @mouseenter="isReactionHovered = true" @mouseleave="isReactionHovered = false">
                    <div class="relative py-1 flex w-[fit-content] px-1.5 items-center justify-between" :class="[{ 'creator-reaction__wrapper': !userPostedReaction }]">
                        <CreatorReactions class="edit-button__button" :userReaction="userPostedReaction" :positionListReactions="'left'" @selectReaction="selectReaction" />
                        <div class="like" :class="[{ active: isSelectedReaction }]"></div>
                    </div>
                </div>
            </div>
            <div class="ml-auto pr-7 shrink-0 flex items-center gap-1.5" v-if="isMobile">
                <div v-if="!item.read" class="mark-new-dot h-1.5 w-1.5 bg-primary rounded"></div>
                <span class="header__date text-[14px] text-[gray5]"> {{ notificationPublishTime }}</span>
            </div>
        </div>
        <ContextMenu v-if="showSubmenu" :event="submenuEvent" :items="submenuBtn" @changedValue="submenuClick" @closeContextMenu="toggleSubmenu"> </ContextMenu>
    </div>
</template>

<script>
import { mapState } from 'pinia';
import { useUserStore } from '@/stores/userStore';

import BaseAvatar from '@/components/ui/BaseAvatar.vue';
import BaseButton from '@/components/ui/baseButton/BaseButton.vue';
import ContextMenu from '@/components/ui/ContextMenu/ContextMenu.vue';
import FieldList from '@/components/comments/FieldList.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import ActivityField from '@/components/comments/ActivityField.vue';
import CreatorReactions from '@/components/reactions/CreatorReactions.vue';
import commentsApi from '@/api/comments';

import { getFullName, addListenerForMentions, removeListenerForMentions } from '@/helpers';
import { toRaw } from 'vue';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
export default {
    name: 'NotificationsItem',
    components: {
        BaseAvatar,
        BaseButton,
        ContextMenu,
        FieldList,
        BaseIcon,
        ActivityField,
        CreatorReactions
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    emits: ['deleteNotification', 'markAsRead', 'markAsUnread', 'openNotificationInstance', 'closeNotificationBlock', 'updateReaction', 'createReaction'],
    data() {
        return {
            showSubmenu: false,
            submenuEvent: null,
            reaction: null,
            comment: null,
            isReactionHovered: false,
            isSelectedReaction: false,
            reactionAnimationTimer: null,
            isReactionCreated: false,
            hideMessage: false,
            isLargeMessage: false,
            showMessageBtnText: this.$t('buttons.showMore')
        };
    },
    computed: {
        ...mapState(useUserStore, ['isMobile', 'user', 'currentAccountId']),
        notificationPublishTime() {
            return dayjs(this.item?.created_time)?.fromNow();
        },
        areNotificationRead() {
            return this.item?.read;
        },
        submenuBtn() {
            const submenu = [
                {
                    id: 'markAsRead',
                    label: this.$t('menu.markAsRead'),
                    type: 'button',
                    icon: 'tick-02'
                },
                {
                    id: 'deleteNotification',
                    label: this.$t('menu.Delete'),
                    type: 'button',
                    icon: 'delete-02'
                }
            ];

            if (this.item.read) {
                submenu[0].id = 'markAsUnread';
                submenu[0].label = this.$t('menu.markAsUnread');
                submenu[0].icon = 'mail-02';
            }

            return submenu;
        },
        fullName() {
            return getFullName(this.item.user_initiator);
        },
        performedAction() {
            const { event } = this.item.activity_data;
            let activity = this.$t('notifications.changed');
            switch (event) {
                case 'update':
                    activity = this.$t('notifications.changed');
                    break;
                case 'added':
                    activity = this.$t('notifications.added');
                    break;
                case 'deleted':
                    activity = this.$t('notifications.deleted');
                    break;
                case 'approve':
                    activity = this.$t('notifications.createdTheApproval');
                    break;
                case 'mention':
                    activity = this.$t('notifications.mentionedYou');
                    break;
                case 'insert':
                    activity = this.$t('notifications.inserted');
                    break;
                case 'you_added':
                    activity = this.$t('notifications.youAdded');
                    break;
                case 'news_item_insert':
                    activity = this.$t('notifications.newsItemInsert');
                    break;
                case 'comment_insert':
                    activity = this.$t('notifications.commentInsert');
                    break;
                case 'comment_update':
                    activity = this.$t('notifications.commentUpdate');
                    break;
                case 'comment_delete':
                    activity = this.$t('notifications.commentDelete');
                    break;

                default:
                    break;
            }
            return activity;
        },
        instanceLabel() {
            return this.item?.activity_data.entity_name ?? '';
        },
        instanceName() {
            return this.item?.activity_data.instance_name || '';
        },
        userPostedReaction() {
            return this.reaction?.icon ? this.reaction : false;
        }
    },
    methods: {
        deleteNotification() {
            this.$emit('deleteNotification', this.item.id);
        },
        openNotificationInstance(notification, event) {
            if (event?.target?.dataset?.action === 'more' || this.isReactionHovered) {
                return;
            }
            if (event?.target?.dataset?.element === 'button') {
                this.$emit('closeNotificationBlock');
                return;
            }
            this.$emit('openNotificationInstance', notification);
            if (!this.item.read) {
                this.$emit('markAsRead', this.item.id);
            }
        },

        toggleSubmenu(e) {
            if (this.showSubmenu) {
                this.$analytics.addEvent({
                    category: 'Notification card',
                    action: 'lmbc',
                    label: 'Hide submenu for notification',
                    entity: 'notifications',
                    instance: this.item.id
                });
                this.showSubmenu = false;
                this.submenuEvent = null;
            } else {
                this.$analytics.addEvent({
                    category: 'Notification card',
                    action: 'lmbc',
                    label: 'Show submenu for notification',
                    entity: 'notifications',
                    instance: this.item.id
                });
                this.showSubmenu = true;
                this.submenuEvent = e;
            }
        },
        submenuClick(btn) {
            if (btn && btn.id) {
                this.$analytics.addEvent({
                    category: 'Notification card',
                    action: 'lmbc',
                    label: 'Click submenu ' + btn.id + ' of notification',
                    entity: 'notifications',
                    instance: this.item.id
                });
            }
            switch (btn?.id) {
                case 'markAsRead':
                    this.$emit('markAsRead', this.item.id);
                    break;

                case 'markAsUnread':
                    this.$emit('markAsUnread', this.item.id);
                    break;

                case 'deleteNotification':
                    this.$emit('deleteNotification', this.item.id);
                    break;

                default:
                    break;
            }
            // this.toggleSubmenu();
        },

        determinePerformedAction({ activity_type }) {
            let activity = this.$t('comments.changed');
            switch (activity_type) {
                case 'update':
                    activity = this.$t('comments.changed');
                    break;
                case 'added':
                    activity = this.$t('comments.added');
                    break;
                case 'deleted':
                    activity = this.$t('comments.deleted');
                    break;
                case 'approve':
                    activity = this.$t('comments.approve');
                    break;
                case 'mention':
                    activity = this.$t('comments.mention');
                    break;

                default:
                    break;
            }
            return activity;
        },

        addMentionsListener() {
            addListenerForMentions(this.$refs.notificationMessage, this.openUserInformation);
        },
        removeMentionsListener() {
            removeListenerForMentions(this.$refs.notificationMessage, this.openUserInformation);
        },
        openUserInformation(e) {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            const userId = e.target?.dataset?.id;
            if (userId)
                this.$modal.open({
                    id: userId,
                    name: 'UserInformation',
                    useInFavorite: false,
                    useCopyLink: false,
                    props: {
                        userId
                    }
                });
        },
        openUserInfo() {
            //user
            this.$analytics.addEvent({
                category: 'Notification card',
                action: 'lmbc',
                label: 'Click show user information from notification',
                entity: 'notifications',
                instance: this.item.id
            });
        },

        // --------- reaction block --------

        async selectReaction(reaction) {
            if (!this.item.read) {
                this.$emit('markAsRead', this.item.id);
            }
            await this.getCommentById(this.item.activity_data?.comment_id);
            this.isSelectedReaction = false;

            setTimeout(() => {
                this.isSelectedReaction = true;
            }, 0);

            if (this.reactionAnimationTimer) {
                clearTimeout(this.reactionAnimationTimer);
            }

            this.reactionAnimationTimer = setTimeout(() => {
                this.isSelectedReaction = false;
            }, 1000);

            const currentReaction = this.reaction?.icon;

            const newReaction = {
                icon: currentReaction && reaction.icon === currentReaction ? null : reaction.icon,
                comment_id: this.item.activity_data?.comment_id,
                user: toRaw(this.user),
                user_id: this.user.id
            };

            if (this.isReactionCreated) {
                await this.updReactionToComment(newReaction);
            } else {
                await this.createReactionToComment(newReaction);
            }
        },
        async getCommentById() {
            if (this.reaction) return;
            if (this.item?.activity_data?.comment_id) {
                const response = await commentsApi.getCommentById(this.item?.activity_data?.comment_id);
                if (response) {
                    this.isReactionCreated = response?.likes?.some(like => like?.user?.id === this.user?.id);
                }
            }
        },

        async createReactionToComment(like) {
            const response = await commentsApi.createReaction(like);
            if (response) {
                this.reaction = like;
            }
        },
        async updReactionToComment(like) {
            const response = await commentsApi.updReaction(like.comment_id, like.user_id, like);
            if (response) {
                this.reaction = like;
            }
        },
        showMessage() {
            this.hideMessage = !this.hideMessage;
            this.showMessageBtnText = this.hideMessage ? this.$t('buttons.showMore') : this.$t('buttons.showLess');
        },
        checkAllowedTextLength() {
            const message = this.item?.activity_data?.message;
            if (typeof message == 'string') {
                const plainText = message?.replace(/<[^>]*>/g, '');
                const characterCount = plainText?.length;
                this.hideMessage = characterCount > 200;
                this.isLargeMessage = characterCount > 200;
            } else {
                console.log('object detected', this.item.activity_data);
            }
        }
    },
    mounted() {
        this.addMentionsListener();
        this.checkAllowedTextLength();
    },
    updated() {
        this.addMentionsListener();
    }
};
</script>

<style lang="scss" scoped>
.notification__item {
    transition: background-color var(--sp-transition-duration, 250ms) var(--sp-transition-timing-function, cubic-bezier(0.4, 0, 0.2, 1));

    &:hover {
        background-color: #f5f9ff;
        .notification__description .description__header .header__menu--btn {
            display: flex;
        }
    }
}
.header__date {
    color: #cbcaca;
}
.notification__description {
    max-width: calc(100% - 40px - 1rem);

    .description__header {
        .header__date {
            color: #cbcaca;
        }

        .header__menu--btn {
            position: absolute;
            top: 12px;
            right: 12px;
        }
    }
    .description__main {
        .main__task--name {
            margin-bottom: 5px;
        }
    }
}

.notification-action {
    position: relative;
    width: 20px;
    margin-left: 8px;
}

.description__value {
    .description__wrapper {
        margin-top: -4px;

        & > .field {
            max-width: 100%;
            margin-top: 4px;
            margin-left: 4px;
            font-size: 13px;
        }
    }
}

.creator-reaction__wrapper {
    visibility: hidden;
    opacity: 0;
    transition: all 350ms ease-out;
}

.notification__item:hover {
    .creator-reaction__wrapper {
        visibility: visible;
        opacity: 1;
    }
}

.edit-button__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
    margin: 0;
    color: var(--gray2);
    fill: var(--gray2);
    background-color: transparent;
    border: none;

    &:hover,
    &:focus {
        color: var(--gray5);
        fill: var(--gray5);
    }
}

.edit-button__button + .edit-button__button {
    margin-left: 8px;
}

.like {
    position: absolute;
    width: 60px;
    height: 60px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: url('@/assets/images/emojis/emojis-animation.png') no-repeat;
    background-position: 0 0;
    cursor: pointer;
}

.like.active {
    transition-timing-function: ease-out;
    animation: like 1s steps(28);
}

@keyframes like {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 100% 0;
    }
}
</style>
