<template>
    <div class="form-container text-black" :class="[isMobile ? 'w-[' + documentWidth + ']' : 'w-[500px]']">
        <div class="form__header flex justify-end border-b border-gray3 p-1.5">
            <BaseButton variant="text" color="third" size="xs" @click.stop="$emit('closeModal')" v-tooltip:top="$t('buttons.close')">
                <template v-slot:icon> <BaseIcon class="w-6 h-6" name="Clouse" /></template>
            </BaseButton>
        </div>
        <div class="tracking-form mt-1.5" v-if="trackingList.length || activeTrackingTask">
            <BaseInputStyled
                class="mt-1 mb-2 px-4"
                v-model:value="searchText"
                :debounceTime="0"
                :placeholder="$t('inputs.search')"
                autofocus
                showResetButton
                :placeholderHideNotEmpty="true"
                variant="underline"
                iconName="search-01"></BaseInputStyled>
            <div class="tracking-item-wrap flex justify-between border-b-2 border-gray3" v-if="activeTrackingTask">
                <div class="tracking-item__label cursor break-words whitespace-pre-line" @click="openTaskCard(activeTrackingTask.instance_id)">
                    {{ activeTrackingTask.name }}
                </div>
                <div class="tracking-item__btn shrink-0">
                    <div class="tracking__time flex items-center">
                        <BaseIcon class="w-6 h-6 fill-gray2 mr-2" name="time-quarter-pass" />
                        <base-timer
                            class="tracking__duration min-w-[61px] shrink-0"
                            v-if="activeTrackingTask"
                            :startTime="activeTrackingTask.started_date"
                            :duration="activeTrackingTask.duration"></base-timer>
                        <span class="tracking__duration" v-else>{{ formatDuration(activeTrackingTask.duration) }}</span>
                    </div>
                    <BaseButton
                        v-if="activeTrackingTask?.started_date"
                        class="ml-2"
                        name="topbarPause"
                        size="sm"
                        color="secondary"
                        v-tooltip:bottom="$t('buttons.stop')"
                        @click="pauseTrackTime(activeTrackingTask)">
                        <template v-slot:icon>
                            <BaseIcon class="w-6 h-6" name="Pause" />
                        </template>
                    </BaseButton>
                    <BaseButton
                        v-else
                        class="ml-2"
                        name="topbarPlay"
                        size="sm"
                        color="secondary"
                        v-tooltip:bottom="$t('buttons.toStart')"
                        @click="startTrackTime(activeTrackingTask)">
                        <template v-slot:icon>
                            <BaseIcon class="w-6 h-6" name="Play" />
                        </template>
                    </BaseButton>
                    <BaseButton
                        class="ml-2"
                        name="topbarFinish"
                        size="sm"
                        color="secondary"
                        v-tooltip:bottom="$t('buttons.finish')"
                        @click="stopTrackTime(activeTrackingTask, $event)">
                        <template v-slot:icon>
                            <BaseIcon class="w-6 h-6" name="flag-02" />
                        </template>
                    </BaseButton>
                </div>
            </div>
            <ul class="tracking-form__list scroll-wrap max-h-[340px]">
                <NewBaseListItem class="tracking-item-wrap flex justify-between" v-for="item in filteredTrackingList" :key="item.id">
                    <div class="tracking-item__label cursor break-words whitespace-pre-line" @click="openTaskCard(item.instance_id)">
                        {{ item.name }}
                    </div>
                    <div class="tracking-item__btn shrink-0">
                        <div class="tracking__time flex items-center shrink-0">
                            <BaseIcon class="w-6 h-6 fill-gray2 mr-2" name="time-quarter-pass" />
                            <span class="task-duration min-w-[40px]">{{ formatDuration(item.duration) }}</span>
                        </div>
                        <BaseButton
                            v-if="item?.started_date"
                            class="ml-2"
                            name="topbarPause"
                            size="sm"
                            color="third"
                            v-tooltip:bottom="$t('buttons.stop')"
                            @click="pauseTrackTime(item)">
                            <template v-slot:icon>
                                <BaseIcon class="w-6 h-6" name="Pause" />
                            </template>
                        </BaseButton>
                        <BaseButton v-else class="ml-2" name="topbarPlay" size="sm" color="third" v-tooltip:bottom="$t('buttons.toStart')" @click="startTrackTime(item)">
                            <template v-slot:icon>
                                <BaseIcon class="w-6 h-6" name="Play" />
                            </template>
                        </BaseButton>
                        <BaseButton class="ml-2" name="topbarFinish" size="sm" color="third" v-tooltip:bottom="$t('buttons.finish')" @click="stopTrackTime(item, $event)">
                            <template v-slot:icon>
                                <BaseIcon class="w-6 h-6" name="flag-02" />
                            </template>
                        </BaseButton>
                    </div>
                </NewBaseListItem>
            </ul>

            <div v-if="this.searchText?.length && !this.filteredTrackingList?.length" class="p-1">
                <p>{{ $t('labels.thereAreNoTasksWithThisNameInTheList') }}</p>
            </div>
        </div>
        <div v-else class="flex items-center justify-center p-[50px]">
            <div class="empty__container flex flex-col items-center justify-center">
                <img src="@/assets/images/Flag_perspective_matte.png" alt="Flag_perspective_matte" class="min-w-[100px] w-[100px] h-[100px]" />
                <span class="text-gray5 text-sm text-center max-w-[204px]">
                    {{ $t('labels.thereIsNothingHereYetAddSomething') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTimer from '@/components/ui/BaseTimer';
import BaseInputStyled from '@/components/ui/BaseInputStyled';
import BaseButton from '@/components/ui/baseButton/BaseButton.vue';
import BaseIcon from '@/components/ui/BaseIcon';
import NewBaseListItem from '@/components/ui/NewBaseListItem.vue';
import { dateInIntToStr } from '@/helpers';

import { mapState } from 'pinia';
import { useTasksStore } from '@/stores/tasksStore';
import { useUserStore } from '@/stores/userStore';

export default {
    name: 'TopBarTrackingForm',
    components: {
        BaseButton,
        BaseTimer,
        BaseIcon,
        BaseInputStyled,
        NewBaseListItem
    },
    props: {
        trackingList: {
            type: Array
        },
        activeTrackingTask: {
            type: Object
        }
    },
    emits: ['closeModal', 'submit', 'startTrackTime', 'pauseTrackTime', 'stopTrackTime'],
    data() {
        return {
            searchText: '',

            documentWidth: 0
        };
    },
    computed: {
        ...mapState(useTasksStore, ['getTaskEntityId']),
        ...mapState(useUserStore, ['isMobile']),
        filteredTrackingList() {
            const loweredSearchText = this.searchText.toLowerCase();
            return this.trackingList.filter(el => el?.name?.toLowerCase()?.includes(loweredSearchText));
        }
    },
    methods: {
        formatDuration(value) {
            return dateInIntToStr(Number(value));
        },
        startTrackTime(item) {
            this.$emit('startTrackTime', item);
        },
        stopTrackTime(item, event) {
            this.$emit('stopTrackTime', item, event);
        },
        pauseTrackTime(item) {
            this.$emit('pauseTrackTime', item);
        },
        openTaskCard(taskId) {
            // this.$router.push({
            //   name: "task",
            //   params: { id: taskId },
            // });
            // console.log("openTaskCard", taskId);
            const params = {
                id: taskId,
                entityId: this.getTaskEntityId,
                name: 'TaskCard',
                props: {
                    id: taskId
                }
            };
            this.$modal.open(params);
        },
        watchForWindowsResize() {
            this.documentWidth = window.innerWidth;
        }
    },
    mounted() {
        this.documentWidth = window.innerWidth;
        window.addEventListener('resize', this.watchForWindowsResize);
    },
    unmounted() {
        window.removeEventListener('resize', this.watchForWindowsResize);
    }
};
</script>

<style lang="scss" scoped>
.tracking-form {
    /* max-height: 75vh; */
}
.tracking-item-wrap {
    @apply flex items-center py-2.5 px-4;
    .tracking-item__btn {
        display: flex;
        align-items: center;
        min-width: 100px;
        cursor: pointer;
        .tracking-icon {
            svg {
                width: 22px;
                height: 22px;
            }
            &:hover {
                svg {
                    path {
                        fill: rgba(38, 147, 255, 1);
                    }
                }
            }
        }
    }
}
.tracking__duration {
    @apply text-black text-sm leading-6 font-medium;
}

.tracking-icon__pause {
    fill: none;
    stroke: #000;
}
.tracking-item__label {
    /* width: 440px; */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 3.6em;
    line-height: 1.8em;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;

    white-space: pre-line;
    overflow-wrap: break-word;
}
</style>
