<template>
    <FlyoutWrapper
        ref="flyoutRef"
        :event="props.event"
        :maxHeight="props.maxHeight"
        :showArrow="props.showArrow"
        :aside="props.aside"
        :showFullSlot="props.showFullSlot"
        :positionOnMouse="props.positionOnMouse"
        @closeFlyoutWrapper="
            emit('closeContextMenu');
            emit('close');
        "
        @mouseenter="emit('mouseenter')"
        @mouseleave="emit('mouseleave')"
        @componentApi="
            (methods, id) => {
                emit('componentApi', methods, id);
            }
        ">
        <base-input-styled
            class="px-4 pt-2.5"
            :class="[{ 'pb-2': !filteredOptions.length }]"
            v-if="search"
            :placeholder="$t('inputs.search')"
            :value="searchValue"
            @input="handleSearchChange"
            :debounceTime="400"
            autofocus
            placeholderHideNotEmpty
            variant="underline"></base-input-styled>

        <div v-if="filteredOptions.length" class="w-full overflow-auto scroll-wrap py-2.5 select-none">
            <component
                v-for="item in filteredOptions"
                :key="item.id"
                :is="getComponentType(item.type)"
                :data="item"
                :timeParentCreatedForSubMenu="timeComponentCreated"
                :iconHeight="props.iconHeight"
                :iconWrapperHeight="props.iconWrapperHeight"
                :isTop="item.top" />
        </div>

        <div v-if="$slots.suffix" class="context-menu__suffix">
            <slot name="suffix"></slot>
        </div>
    </FlyoutWrapper>
</template>

<script setup>
import { ref, provide, computed, watch } from 'vue';
import BaseInputStyled from '@/components/ui/BaseInputStyled.vue';
import { InjectionKeyHandleSearchChange, InjectionKeyToggleValue, InjectionKeyButtonClick, InjectionKeyTransferData } from './keys.js';
import { validateItems } from './propsValidation';
const emit = defineEmits(['changedValue', 'closeContextMenu', 'close', 'mouseenter', 'mouseleave', 'componentApi']);

const props = defineProps({
    event: {
        type: Object,
        default: null
    },
    items: {
        type: Array,
        default: () => []
    },
    iconHeight: {
        type: Number,
        default: 24
    },
    iconWrapperHeight: {
        type: Number,
        default: 24
    },
    maxHeight: {
        type: Number,
        default: 500
    },
    buttonActive: String,
    search: {
        type: Boolean,
        default: false
    },
    searchBy: String,
    showArrow: {
        type: Boolean,
        default: false
    },
    aside: {
        type: Boolean,
        default: false
    },
    showFullSlot: {
        type: Boolean,
        default: false
    },
    positionOnMouse: {
        type: Boolean,
        default: false
    }
});

const componentItems = ref(validateItems(props.items));
const flyoutRef = ref();

watch(
    () => props.items,
    newItems => {
        componentItems.value = [...newItems];
    },
    { deep: true }
);

import DividerLine from './components/DividerLine.vue';
import GroupCaption from './components/GroupCaption.vue';
import OptionToggle from './components/OptionToggle.vue';
import OptionCheck from './components/OptionCheck.vue';
import OptionCheckSingle from './components/OptionCheckSingle.vue';
import OptionButton from './components/OptionButton.vue';
import OptionUrl from './components/OptionUrl.vue';
import OptionAccordion from './components/OptionAccordion.vue';
import FlyoutWrapper from '../FlyoutWrapper.vue';

function getComponentType(type) {
    const typeToComponent = {
        toggle: OptionToggle,
        check: OptionCheck,
        checkSingle: OptionCheckSingle,
        button: OptionButton,
        caption: GroupCaption,
        divider: DividerLine,
        url: OptionUrl,
        accordion: OptionAccordion
    };

    return typeToComponent[type] || '';
}

const toggleValue = prop => {
    componentItems.value = componentItems.value.map(el =>
        el.id === prop.id
            ? {
                  ...el,
                  value: prop.type !== 'checkSingle' ? !el.value : el.items.find(elemSingle => elemSingle.label === prop.label).id
              }
            : el
    );
    emit('changedValue', {
        id: prop.id,
        value: componentItems.value.find(el => el.id === prop.id).value
    });
};

provide(InjectionKeyToggleValue, toggleValue);

const buttonClick = (param, buttonEvent, object, moreBtnClicked = false, isMenuClosingForbiddenAfterClick = false) => {
    emit(
        'changedValue',
        {
            id: param,
            value: moreBtnClicked ? 'more' : 'click'
        },
        buttonEvent,
        object,
        isMenuClosingForbiddenAfterClick
    );
    if (!isMenuClosingForbiddenAfterClick) {
        emit('closeContextMenu');
        emit('close');
    }
};
provide(InjectionKeyButtonClick, buttonClick);

const transferData = (...data) => {
    emit('changedValue', ...data);
    emit('closeContextMenu');
    emit('close');
};
provide(InjectionKeyTransferData, transferData);

const searchValue = ref('');
const handleSearchChange = text => {
    searchValue.value = text;
};

const filteredOptions = computed(() =>
    componentItems.value.filter(el => {
        if (el.hide) {
            return false;
        }
        if (searchValue.value === '') {
            return true;
        }
        if (el.type === 'search') {
            return true;
        } else if (el.type === 'caption') {
            return false;
        } else if (el.type === 'checkSingle' && el.items.some(item => item.label.toLowerCase().includes(searchValue.value.toLowerCase()))) {
            return true;
        } else if (el.label) {
            return el.label.toLowerCase().includes(searchValue.value.toLowerCase());
        }
        return false;
    })
);
provide(InjectionKeyHandleSearchChange, handleSearchChange);

const timeComponentCreated = performance.now();

defineExpose({
    getWidth: () => flyoutRef.value?.getWidth()
});
</script>

<style lang="scss">
.context-menu__element {
    height: 42px;
    padding: 0 16px;
    display: block;
    color: #131416;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    fill: #c0c2cd;

    &:hover {
        cursor: pointer;
        color: #519df5;
    }

    &_readonly {
        color: #c0c2cd !important;
        fill: #c0c2cd;
        cursor: not-allowed !important;
    }
}
</style>
